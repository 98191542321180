import React from 'react'
import SettingTab from '../Tabs/SettingTab'
import Header from '../Header/Header'
const User = () => {
    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 text-dark">
                        <h1>User</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User