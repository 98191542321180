import React, {useState, useContext, useLayoutEffect, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './SideBar.css'
import {ApplicationContext} from '../../context'
import swal from 'sweetalert';
import axios from 'axios';
import {SCRAPPER_BACKEND_PATH} from "../../config/constants";
import {TRIGGER_TYPE_PROFILES, TRIGGER_TYPE_COMPANIES, TRIGGER_TYPE_JOBS} from "../../constants";

const SideBar = ({page, existedSearchFilters}) => {
    const [jobPosting, setJobPosting] = useState('')
    const [toggleTimeInRole, setToggleTimeInRole] = useState(false)

    const [selectedIndustry, setSelectedIndustry] = useState('');

    const [roleTrigger, setRoleTrigger] = useState(false);
    const [roleTime, setRoleTime] = useState(false);
    const [jobRole, setJobRole] = useState(false);
    const [round, setRound] = useState(false);

    const [selectedHeadCountFilters, setSelectedHeadCountFilters] = useState([]);

    // States
    const [jobTitle, setJobTitle] = useState('');
    const [jobTitleFilter, setJobTitleFilter] = useState('');
    const [fundingDayFilterDropdown, setFundingDayFilterDropdown] = useState(false);
    const [industryDropdown, setIndustryDropdown] = useState(false);
    const [fundingAmountDropdown, setFundingAmountDropdown] = useState(false);
    const [companyLocationDropdown, setCompanyLocationDropdown] = useState(false);
    const [triggerTypeDropdown, settriggerTypeDropdown] = useState(false)
    const [headCoundDropdown, setheadCoundDropdown] = useState(false)
    const [locationDropdown, setlocationDropdown] = useState(false)
    const [technologyDropdown, settechnologyDropdown] = useState(false)
    const [jobTitleDropdown, setjobTitleDropdown] = useState(false)
    const [age, setAge] = useState('');
    const [fundingRounds, setFundingRounds] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [fundingAmount, setFundingAmount] = useState('');
    const [companyLocation, setCompanyLocation] = useState('');
    const [monthProfileValue, setMonthProfileValue] = useState('')
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [locationForPeople, setLocationForPeople] = useState(null)
    const [filtersApplied, setFiltersApplied] = useState(false);

    const [search, setSearch] = useSearchParams();

    const geoLocations = {
        102299470: 'United Kingdom',
        103644278: 'United States Of America',
        101282230: 'Germany',
        105015875: 'France',
    }

    const headCountFilters = [
        {
            text: '1-10',
            value: {from: 1, to: 10}
        },
        {
            text: '11-50',
            value: {from: 11, to: 50}
        },
        {
            text: '51-200',
            value: {from: 51, to: 200}
        },
        {
            text: '201-500',
            value: {from: 201, to: 500}
        },
        {
            text: '501-1000',
            value: {from: 501, to: 1000}
        },
        {
            text: '1,001-5000',
            value: {from: 1001, to: 5000}
        },
        {
            text: '5,001-10000',
            value: {from: 5001, to: 10000}
        },
        {
            text: '10001+',
            value: {from: 10001, to: null}
        },
    ]

    const fundingRoundItems = [
        'Angel', 'Crowdfunding', 'Pre-Seed', 'Seed',
        'Post-Seed / Pre-Series A', 'Series A', 'Series B',
        'Series C', 'Series D', 'Series E', 'Series F',
        'Series G', 'Series H', 'Series I', 'Series J',
        'Private Equity', 'Venture - Series Unknown',
        'Debt Financing', 'Corporate Round', 'Grant',
    ];

    const fundingRoundDayFilters = [
        {days: 7, value: 'Last 7 days'},
        {days: 14, value: 'Last 14 days'},
        {days: 30, value: 'Last 30 days'},
        {days: 90, value: 'Last 90 days'},
        {days: 360, value: 'Past Year'},
    ];

    const [fundingRoundDayFilter, setFundingRoundDayFilter] = useState(null);

    const industryItems = [
        "accounting", "advertising & communication", "agriculture",
        "angels/ venture capital/ private equity", "animal husbandry & forestry",
        "audit & tax", "automobile", "aviation", "banking", "bio-technology",
        "business services", "chemicals & fertilizers", "clean technology",
        "conglomerate", "consulting", "consumer products", "consumer services",
        "defence", "e-commerce", "economic development", "education",
        "energy & utilities", "engineering", "financial services", "gambling",
        "gaming", "government", "healthcare", "human resources", "insurance",
        "it services & outsourcing", "legal", "logistics & supply chain", "manufacturing",
        "media & entertainment", "medical device", "metals & mining", "not-for-profit/ ngo",
        "others", "pharmaceuticals", "procurement & construction", "publishing", "real estate",
        "restaurants & food services", "retail", "security", "shipping & ports", "spac/blank check",
        "sports", "technology", "telecommunication", "textiles", "tourism & hospitality",
        "trading", "transportation",
    ]

    const {processTriggerRequest, handlePagination, dispatch, state} = useContext(ApplicationContext);

    let obj = {
        location: 'California',
        name: age === "Recent Role Change" ? jobPosting : jobTitle,
        triggerType: age,
        timeOfRole: age === "Recent Role Change" && parseInt(monthProfileValue)
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleSaveTrigger = async () => {
        if (age === 'Funding Round') {
            if (!fundingRounds?.length) {
                swal("Please select at least one funding round", "", "error");
                return
            }

            await processTriggerRequest(TRIGGER_TYPE_COMPANIES, {
                fundingRounds: fundingRounds.filter(f => f),
                days: fundingRoundDayFilter,
                uid: localStorage.getItem("id")
            })
            setSearch({
                name: encodeURIComponent(fundingRounds),
                type: 'companyTriggerType'
            });
        } else if (age === 'Job Role') {
            if (!selectedLocations || !selectedLocations.length) {
                swal("Please Select Location", "", "error");
                return
            }

            await processTriggerRequest(TRIGGER_TYPE_JOBS, {
                name: obj.name,
                location: selectedLocations,
                uid: localStorage.getItem("id")
            });
            setSearch({
                name: obj.name,
                location: selectedLocations,
                type: 'jobTriggerType'
            });
        } else if (age === 'Recent Role Change') {
            await processTriggerRequest(TRIGGER_TYPE_PROFILES, {
                name: obj.name,
                location: locationForPeople,
                triggerType: 'Recent Role Change',
                timeOfRole: 7,
                uid: localStorage.getItem("id")
            });

            setSearch({
                name: obj.name,
                location: locationForPeople,
                type: 'profileTriggerType'
            });
        }
    }

    const handleResetTrigger = () => {
        dispatch({
            type: 'PROCESS_DATA_LIST',
            payload: {type: state.dataList?.type, data: []}
        });
        setSearch([]);

        handleClearFilters();

        setJobRole(false);
        setRoleTrigger(false);
        setRound(false);
        setSelectedIndustry(null);
        setlocationDropdown(false);
        setJobPosting('');
        setJobTitle('');
        setAge('');
        setSelectedLocations(null);
        setLocationForPeople(null);
        setFundingRounds([]);
    }

    const handleApplyFilter = async () => {
        setFiltersApplied(true);
        dispatch({ type: 'SET_TIME_IN_ROLE_FILTER_APPLIED', payload: !!monthProfileValue });
        const queryParams = {};
        search.forEach((value, key) => {
            queryParams[key] = value;
        });
        const headCounts = headCountFilters.reduce((acc, headCount, i) => {
            if (selectedHeadCountFilters.includes(i)) {
                acc.push(headCount.value);
            }

            return acc;
        }, []);

        const params = {
            uid: localStorage.getItem("id"),
            queryParams,
            filters: {
                timeInRole: monthProfileValue,
                headCounts,
                jobTitle: jobTitleFilter,
                fundingRoundDayFilter,
                industries,
                fundingAmount,
                companyLocation
            }
        };

        const response = await axios.get(`${SCRAPPER_BACKEND_PATH}/apply-filters`, {params});
        const data = response.data?.data;

        dispatch({
            type: 'PROCESS_DATA_LIST',
            payload: {type: state.dataList?.type, data: data}
        });

        handlePagination(0, data);
    }

    const handleClearFilters = () => {
        setFiltersApplied(false);
        setMonthProfileValue('');
        setSelectedHeadCountFilters([]);
        setJobTitleFilter('');
        setIndustries([]);
        setFundingAmount('');
        setFundingRoundDayFilter(null);
    }

    const handleNewInRoleDropdown = () => {
        setAge("Recent Role Change");
        setRoleTrigger(true);
        setJobRole(false);
        setRoleTrigger(true);
        setRoleTime(true);
        setRound(false);
        setlocationDropdown(false);
    }

    const handleJobRoleDropdown = () => {
        setAge("Job Role");
        setRoleTrigger(false);
        setJobRole(true);
        setRoleTime(false);
        setRound(false);
        setlocationDropdown(true)
    }

    const handleFundingRoundDropdown = () => {
        setAge("Funding Round");
        setRound(true);
        setRoleTrigger(false);
        setJobRole(false);
        setRoleTime(false);
        setlocationDropdown(false)
    }

    const changeHeadCountHandler = (headCountIndex) => {
        const _selectedHeadCountFilters = [...selectedHeadCountFilters];
        const index = _selectedHeadCountFilters.indexOf(headCountIndex);
        _selectedHeadCountFilters.includes(headCountIndex) ? _selectedHeadCountFilters.splice(index, 1) : _selectedHeadCountFilters.push(headCountIndex);
        setSelectedHeadCountFilters(_selectedHeadCountFilters);
    }

    const hasData = state.dataList?.data?.length > 0;

    useEffect(() => {
        // Persist Dropdowns
        const type = search.get('type');
        if (type) {
            settriggerTypeDropdown(true);
            switch (type) {
                case 'profileTriggerType':
                    handleNewInRoleDropdown();
                    setJobPosting(search.get('name'));
                    setLocationForPeople(search.get('location'));
                    break;
                case 'jobTriggerType':
                    handleJobRoleDropdown();
                    setJobTitle(search.get('name'));
                    setSelectedLocations(search.get('location'));
                    break;
                case 'companyTriggerType':
                    handleFundingRoundDropdown();
                    let fundingRounds = decodeURIComponent(search.get('name'));
                    fundingRounds = fundingRounds ? fundingRounds.split(',') : [];
                    if (fundingRounds?.length) {
                        setFundingRounds(fundingRounds);
                    }
                    break;
            }
        }

        if (existedSearchFilters) {
            setMonthProfileValue(existedSearchFilters?.timeInRole || '');
            setJobTitleFilter(existedSearchFilters?.jobTitle || '');
            setIndustries(existedSearchFilters?.industries?.length ? existedSearchFilters.industries : []);
            setFundingAmount(existedSearchFilters?.fundingAmount || '');
            setFundingRoundDayFilter(existedSearchFilters?.fundingRoundDayFilter || '');
            setCompanyLocation(existedSearchFilters?.companyLocation || '');
            if (existedSearchFilters?.headCounts?.length) {
                const selectedHeadCounts = existedSearchFilters.headCounts.reduce((acc, hc) => {
                    const headCountIndex = headCountFilters.findIndex(f => f.value.from === parseInt(hc.from) && f.value.to === parseInt(hc.to));
                    if (headCountIndex >= 0) {
                        acc.push(headCountIndex)
                    }
                    return acc;
                }, []);

                setSelectedHeadCountFilters(selectedHeadCounts);
            }

            dispatch({ type: 'SET_TIME_IN_ROLE_FILTER_APPLIED', payload: !!existedSearchFilters?.timeInRole });
        }
    }, []);

    useEffect(() => {
        if (hasData) {
            setToggleTimeInRole(true);
            setheadCoundDropdown(true);
            setjobTitleDropdown(true);
            setFundingAmountDropdown(true);
            setFundingDayFilterDropdown(true);
            setIndustryDropdown(true);
            setFundingAmountDropdown(true);
            setCompanyLocationDropdown(true);
        } else {
            settriggerTypeDropdown(true);
        }
    }, [hasData]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div className="sidebar p-3 rounded ">
                            <div
                                className="sidebar-logo mb-3 border-bottom pb-3 d-flex align-items-center justify-content-between">
                                <div className="row w-100 align-items-center">
                                    <div className="col-md-2 text-dark"><b></b></div>
                                    <div style={{paddingRight: "0px",}} className="col-md-10">
                                        <div
                                            className="sidebar-right-links d-flex align-items-center justify-content-end gap-2">
                                            {
                                                hasData || filtersApplied ? (
                                                    <>
                                                        <button onClick={handleResetTrigger}
                                                                className='fs-12 filter-btn rounded-1 text-center'>
                                                            Reset Trigger
                                                        </button>

                                                        <button onClick={handleClearFilters}
                                                                className='fs-12 filter-btn rounded-1 text-center'>
                                                            Clear Filters
                                                        </button>

                                                        <button className='filter-btn rounded-1 text-center fs-12'
                                                                onClick={handleApplyFilter}>
                                                            Apply Filter
                                                        </button>
                                                    </>

                                                ) : (
                                                    <button className='filter-btn rounded-1 text-center fs-12'
                                                            onClick={handleSaveTrigger}>
                                                        Save Trigger
                                                    </button>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-links">
                                <ul className="p-0 d-flex flex-column gap-4 ">
                                    <li className={`border-bottom pb-2 text-dark border-bottom ${(hasData || filtersApplied) ? 'disabled' : ''}`}>
                                        <div
                                            onClick={() => settriggerTypeDropdown(!triggerTypeDropdown)}
                                            className={triggerTypeDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                            <span className="me-2">
                                                <svg width={20} height={20} fill="#000" viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 18.25a.75.75 0 0 0 0 1.5h6A1.75 1.75 0 0 0 19.75 18V6A1.75 1.75 0 0 0 18 4.25h-6a.75.75 0 0 0 0 1.5h6a.25.25 0 0 1 .25.25v12a.25.25 0 0 1-.25.25h-6Z"/>
                                                    <path fillRule="evenodd"
                                                          d="M3.25 13.115c0 .69.56 1.25 1.25 1.25h4.613l.02.22.054.556a1.227 1.227 0 0 0 1.752.988c1.634-.783 3.212-1.958 4.466-3.267a1.253 1.253 0 0 0 0-1.734l-.1-.104a15.06 15.06 0 0 0-4.366-3.163 1.227 1.227 0 0 0-1.752.988l-.054.555-.02.22H4.5c-.69 0-1.25.56-1.25 1.25v2.241Zm7.303.416a.75.75 0 0 0-.745-.666H4.75v-1.74h5.058a.75.75 0 0 0 .748-.704c.019-.29.042-.581.07-.871l.016-.162a13.556 13.556 0 0 1 3.516 2.607 13.568 13.568 0 0 1-3.516 2.607l-.016-.162a25.457 25.457 0 0 1-.073-.91Z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                            </span>
                                            <span className={triggerTypeDropdown === true ? "text-primary " : ""}>

                                                Trigger Type
                                            </span>
                                            <span
                                                className={triggerTypeDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                {
                                                    triggerTypeDropdown === true ?
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={2} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 12h14"/>
                                                        </svg>
                                                        :
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={1.5} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 5.75v12.5"/>
                                                            <path d="M18.25 12H5.75"/>
                                                        </svg>
                                                }



                                            </span>
                                        </div>
                                        <div
                                            className={triggerTypeDropdown === true ? "custom-dropdown-item mt-3 bg-off-white p-2 border-top border-primary" : "d-none"}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Box sx={{minWidth: 120}}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Select Trigger
                                                                Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={age}
                                                                label="Select Trigger Type"
                                                                onChange={handleChange}
                                                            >
                                                                {page !== 'account' &&
                                                                    <MenuItem value={"Recent Role Change"}
                                                                              onClick={handleNewInRoleDropdown}>New In
                                                                        Role</MenuItem>}
                                                                <MenuItem value={"Job Role"}
                                                                          onClick={handleJobRoleDropdown}>Job Role /
                                                                    Description</MenuItem>
                                                                {page === 'account' &&
                                                                    <MenuItem value={"Funding Round"}
                                                                              onClick={handleFundingRoundDropdown}
                                                                              className="position-relative">Funding
                                                                        Round
                                                                    </MenuItem>}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-md-12">
                                                    <div
                                                        className="year-in-company position-relative text-center text-dark box-shadow">
                                                        {jobRole === true && (
                                                            <div className="form-floating">
                                                                <input className="form-control month-input" type="text"
                                                                       placeholder="Enter Job Description"
                                                                       id="outlined-basic"
                                                                       label="Job Role / Description"
                                                                       variant="outlined0"
                                                                       value={jobTitle}
                                                                       onChange={(e) => setJobTitle(e.target.value)}/>
                                                                <label htmlFor="outlined-basic">Enter Job
                                                                    Description</label>
                                                            </div>
                                                        )}

                                                        {roleTrigger === true && (
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter Role Title"
                                                                    className="form-control month-input"
                                                                    id="outlined-basic1"
                                                                    value={jobPosting}
                                                                    onChange={(e) => setJobPosting(e.target.value)}
                                                                />
                                                                <label htmlFor="outlined-basic1">Enter Role
                                                                    Title</label>
                                                            </div>

                                                        )}
                                                        {locationDropdown && (
                                                            <Box sx={{minWidth: 120}}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-multiple-select-label">Enter
                                                                        Contacts Countries</InputLabel>
                                                                    <Select
                                                                        labelId="demo-multiple-select-label"
                                                                        id="demo-multiple-select"
                                                                        // multiple
                                                                        label="Enter Contacts Countries"
                                                                        value={selectedLocations}
                                                                        onChange={(e) => setSelectedLocations(e.target.value)}
                                                                        // onClick={handleDropdownClickLocation}
                                                                        inputProps={{
                                                                            name: 'selectedLocations',
                                                                            id: 'selectedLocations',
                                                                        }}
                                                                    >
                                                                        {
                                                                            Object.keys(geoLocations).map((key, i) => (
                                                                                <MenuItem key={i}
                                                                                          value={key}>{geoLocations[key]}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>

                                                        )}
                                                        {age === "Recent Role Change" && (
                                                            <Box sx={{minWidth: 120}}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-multiple-select-label">Enter
                                                                        Contacts Countries</InputLabel>
                                                                    <Select
                                                                        labelId="demo-multiple-select-label"
                                                                        id="demo-multiple-select"
                                                                        // multiple
                                                                        label="Enter Contacts Countries"
                                                                        value={locationForPeople}
                                                                        onChange={(e) => setLocationForPeople(e.target.value)}
                                                                        // onClick={handleDropdownClickLocation}
                                                                        inputProps={{
                                                                            name: 'selectedLocations',
                                                                            id: 'selectedLocations',
                                                                        }}
                                                                    >
                                                                        {
                                                                            Object.keys(geoLocations).map((key, i) => (
                                                                                <MenuItem key={i}
                                                                                          value={key}>{geoLocations[key]}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>

                                                        )}

                                                        <div
                                                            className={round === true ? "custom-dropdown-item bg-off-white pt-1 border-top border-primary" : "d-none "}>
                                                            <div className="row">
                                                                <div className="col-md-12 text-center">
                                                                    <Box sx={{minWidth: 120}}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="demo-multiple-select-label">Select
                                                                                Funding Rounds</InputLabel>
                                                                            <Select
                                                                                labelId="demo-multiple-select-label"
                                                                                id="demo-multiple-select"
                                                                                multiple
                                                                                value={fundingRounds}
                                                                                label="Select Funding Round"
                                                                                onChange={e => {
                                                                                    setFundingRounds(e.target.value);
                                                                                }}
                                                                            >
                                                                                {
                                                                                    fundingRoundItems.map((fundingRound, i) => (
                                                                                        <MenuItem key={i}
                                                                                                  value={fundingRound}>{fundingRound}</MenuItem>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>

                                    {(hasData || filtersApplied) && (
                                        <>
                                            {jobRole && (
                                                <>
                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setjobTitleDropdown(!jobTitleDropdown)}
                                                            className={jobTitleDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                            <span>
                                                <span className="me-2">
                                                    <svg width={20} height={20} fill="none" stroke="#000"
                                                         strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M20.25 6H3.75A2.25 2.25 0 0 0 1.5 8.25v10.5A2.25 2.25 0 0 0 3.75 21h16.5a2.25 2.25 0 0 0 2.25-2.25V8.25A2.25 2.25 0 0 0 20.25 6Z"/>
                                                        <path
                                                            d="M6.75 6V4.5A1.5 1.5 0 0 1 8.25 3h7.5a1.5 1.5 0 0 1 1.5 1.5V6"/>
                                                        <path d="M22.5 11.25h-21"/>
                                                        <path
                                                            d="M15 11.25v1.125a.375.375 0 0 1-.375.375h-5.25A.375.375 0 0 1 9 12.375V11.25"/>
                                                    </svg>
                                                </span>
                                                Job Title</span>
                                                            <span
                                                                className={jobTitleDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                {
                                                    jobTitleDropdown === true ?
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={2} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 12h14"/>
                                                        </svg>
                                                        :
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={1.5} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 5.75v12.5"/>
                                                            <path d="M18.25 12H5.75"/>
                                                        </svg>
                                                }
                                            </span>
                                                        </div>
                                                        <div
                                                            className={jobTitleDropdown === true ? "custom-dropdown-item mt-3 bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                            <div className="row">
                                                                <div className="col-md-12 text-center">
                                                                    <Box sx={{minWidth: 120}}>
                                                                        <FormControl fullWidth>
                                                                            <div className="form-floating">
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Enter Job Title"
                                                                                    className="form-control month-input"
                                                                                    id="outlined-basic2"
                                                                                    value={jobTitleFilter}
                                                                                    onChange={(e) => setJobTitleFilter(e.target.value)}
                                                                                />
                                                                                <label htmlFor="outlined-basic2">Enter
                                                                                    Job Title</label>
                                                                            </div>
                                                                        </FormControl>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setheadCoundDropdown(!headCoundDropdown)}
                                                            className={headCoundDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                            <span className={headCoundDropdown === true ? "text-primary " : ""}>
                                                <span className="me-2">
                                                    <svg width={20} height={20} fill="#000" viewBox="0 0 24 24"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"/>
                                                        <path
                                                            d="M8 13.25A3.75 3.75 0 0 0 4.25 17v1.188c0 .754.546 1.396 1.29 1.517 4.278.699 8.642.699 12.92 0a1.537 1.537 0 0 0 1.29-1.517V17A3.75 3.75 0 0 0 16 13.25h-.34c-.185 0-.369.03-.544.086l-.866.283a7.251 7.251 0 0 1-4.5 0l-.866-.283a1.752 1.752 0 0 0-.543-.086H8Z"/>
                                                    </svg>
                                                </span>
                                                Headcount</span>
                                                            <span
                                                                className={headCoundDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                {
                                                    headCoundDropdown === true ?
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={2} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 12h14"/>
                                                        </svg>
                                                        :
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={1.5} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 5.75v12.5"/>
                                                            <path d="M18.25 12H5.75"/>
                                                        </svg>
                                                }
                                            </span>
                                                        </div>
                                                        <div
                                                            className={headCoundDropdown === true ? "custom-dropdown-item bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 text-center">
                                                                    <span><b>Select number of employees</b></span>
                                                                </div>
                                                            </div>
                                                            <ul className="headcount-ul ">
                                                                <li>
                                                                    {
                                                                        headCountFilters.map((headCountFilter, i) => (
                                                                            <div key={i}
                                                                                 className="form-check mb-2">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       checked={selectedHeadCountFilters.includes(i)}
                                                                                       id={`head-count-filter-${i}`}
                                                                                       onChange={() => changeHeadCountHandler(i)}/>
                                                                                <label
                                                                                    className="form-check-label cursor-pointer"
                                                                                    htmlFor={`head-count-filter-${i}`}>
                                                                                    {headCountFilter.text}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </>
                                            )}

                                            {roleTrigger && (
                                                <li className="border-bottom text-dark">
                                                    <div
                                                        onClick={() => setToggleTimeInRole(!toggleTimeInRole)}
                                                        className={jobTitleDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                            <span>
                                                <span className="me-2">
                                                    <svg width={20} height={20} fill="none" stroke="#000"
                                                         strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M20.25 6H3.75A2.25 2.25 0 0 0 1.5 8.25v10.5A2.25 2.25 0 0 0 3.75 21h16.5a2.25 2.25 0 0 0 2.25-2.25V8.25A2.25 2.25 0 0 0 20.25 6Z"/>
                                                        <path
                                                            d="M6.75 6V4.5A1.5 1.5 0 0 1 8.25 3h7.5a1.5 1.5 0 0 1 1.5 1.5V6"/>
                                                        <path d="M22.5 11.25h-21"/>
                                                        <path
                                                            d="M15 11.25v1.125a.375.375 0 0 1-.375.375h-5.25A.375.375 0 0 1 9 12.375V11.25"/>
                                                    </svg>
                                                </span>
                                                Role Time</span>
                                                        <span
                                                            className={toggleTimeInRole === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                {
                                                    toggleTimeInRole === true ?
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={2} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 12h14"/>
                                                        </svg>
                                                        :
                                                        <svg width={20} height={20} fill="none" stroke="#000"
                                                             strokeLinecap="round" strokeLinejoin="round"
                                                             strokeWidth={1.5} viewBox="0 0 24 24"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 5.75v12.5"/>
                                                            <path d="M18.25 12H5.75"/>
                                                        </svg>
                                                }
                                            </span>
                                                    </div>
                                                    <div
                                                        className={toggleTimeInRole ? "custom-dropdown-item bg-off-white p-2 border-top border-primary" : "d-none "}>
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">

                                                                <Box sx={{minWidth: 120}}>
                                                                    <FormControl fullWidth>
                                                                        <div className="form-floating">
                                                                            <input
                                                                                type="number"
                                                                                placeholder="Enter Time In Months"
                                                                                className="form-control month-input set-the-input-1"
                                                                                id="outlined-basic2"
                                                                                value={monthProfileValue}
                                                                                onChange={(e) => setMonthProfileValue(e.target.value)}
                                                                            />
                                                                            <label htmlFor="outlined-basic2">Enter
                                                                                Time In Months</label>
                                                                        </div>
                                                                    </FormControl>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}

                                            {round && (
                                                <>
                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setFundingDayFilterDropdown(!fundingDayFilterDropdown)}
                                                            className={fundingDayFilterDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                                            <span
                                                                className={fundingDayFilterDropdown === true ? "text-primary " : ""}>
                                                                <span className="me-2">
                                                                    <svg width={20} xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 512 512"><path
                                                                        d="M512 152v295.1c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32v-384c0-17.67 14.33-32 32-32h96c17.67 0 32 14.33 32 32v160l139.1-92.24C315.1 121.6 336 133.1 336 152V223.1l139.1-92.24C491.1 121.6 512 133.1 512 152z"/></svg>
                                                                </span>
                                                                Time Filter
                                                            </span>
                                                            <span
                                                                className={fundingDayFilterDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                            {
                                                                fundingDayFilterDropdown === true ?
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={2} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 12h14"/>
                                                                    </svg>
                                                                    :
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={1.5} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 5.75v12.5"/>
                                                                        <path d="M18.25 12H5.75"/>
                                                                    </svg>
                                                            }
                                                        </span>
                                                        </div>
                                                        {
                                                            fundingDayFilterDropdown &&
                                                            <div
                                                                className={round === true ? "custom-dropdown-item bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                                <div className="row mb-2">
                                                                    <div className="col-md-12 text-center">
                                                                        <Box sx={{minWidth: 120}}>
                                                                            <FormControl fullWidth>
                                                                                <InputLabel
                                                                                    id="demo-multiple-select-label">Select
                                                                                    Time Filter</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-multiple-select-label"
                                                                                    id="demo-multiple-select"
                                                                                    value={fundingRoundDayFilter}
                                                                                    label="Select Funding Round"
                                                                                    onChange={e => {
                                                                                        setFundingRoundDayFilter(e.target.value);
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        fundingRoundDayFilters.map(dayFilter => (
                                                                                            <MenuItem key={dayFilter.days}
                                                                                                      value={dayFilter.days}>{dayFilter.value}</MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>

                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setIndustryDropdown(!industryDropdown)}
                                                            className={industryDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                                            <span
                                                                className={industryDropdown === true ? "text-primary " : ""}>
                                                                <span className="me-2">
                                                                    <svg width={20} xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 512 512"><path
                                                                        d="M512 152v295.1c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32v-384c0-17.67 14.33-32 32-32h96c17.67 0 32 14.33 32 32v160l139.1-92.24C315.1 121.6 336 133.1 336 152V223.1l139.1-92.24C491.1 121.6 512 133.1 512 152z"/></svg>
                                                                </span>
                                                                Industries
                                                            </span>
                                                            <span
                                                                className={industryDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                            {
                                                                industryDropdown === true ?
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={2} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 12h14"/>
                                                                    </svg>
                                                                    :
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={1.5} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 5.75v12.5"/>
                                                                        <path d="M18.25 12H5.75"/>
                                                                    </svg>
                                                            }
                                                        </span>
                                                        </div>
                                                        {
                                                            industryDropdown &&
                                                            <div
                                                                className={round === true ? "custom-dropdown-item bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                                <div className="row mb-2">
                                                                    <div className="col-md-12 text-center">
                                                                        <Box sx={{minWidth: 120}}>
                                                                            <FormControl fullWidth>
                                                                                <InputLabel
                                                                                    id="demo-multiple-select-label">Select Industries</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-multiple-select-label"
                                                                                    id="demo-multiple-select"
                                                                                    multiple
                                                                                    value={industries}
                                                                                    label="Select Industries"
                                                                                    onChange={e => {
                                                                                        setIndustries(e.target.value);
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        industryItems.map((industry, i) => (
                                                                                            <MenuItem key={i}
                                                                                                      value={industry}>{industry}</MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>

                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setFundingAmountDropdown(!fundingAmountDropdown)}
                                                            className={fundingAmountDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                                            <span
                                                                className={fundingAmountDropdown === true ? "text-primary " : ""}>
                                                                <span className="me-2">
                                                                    <svg
                                                                        width={20}
                                                                        viewBox="0 0 270.92 270.92"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill="black"
                                                                            d="M218.45 48.17l-166.76 0c-4.38,0 -7.93,3.56 -7.93,7.93 0,4.37 3.55,7.93 7.93,7.93l166.76 0c11.12,0 20.18,9.05 20.18,20.2l0 102.47c0,11.13 -9.06,20.18 -20.18,20.18l-165.97 0c-11.12,0 -20.18,-9.05 -20.18,-20.18l0 -103.26c0,-4.39 -3.56,-7.94 -7.95,-7.94 -4.37,0 -7.92,3.55 -7.92,7.94l0 103.26c0,19.89 16.17,36.05 36.05,36.05l165.97 0c19.88,0 36.05,-16.16 36.05,-36.05l0 -102.47c0,-19.9 -16.17,-36.06 -36.05,-36.06zm-26.65 112.66c-4.82,0 -8.72,-3.91 -8.72,-8.7 0,-4.4 -3.56,-7.95 -7.94,-7.95 -4.38,0 -7.93,3.55 -7.93,7.95 0,10.75 7,19.83 16.65,23.15l0 4.49c0,4.38 3.56,7.95 7.94,7.95 4.39,0 7.93,-3.57 7.93,-7.95l0 -4.49c9.66,-3.32 16.65,-12.4 16.65,-23.15 0,-13.58 -11.02,-24.61 -24.58,-24.61 -4.82,0 -8.72,-3.91 -8.72,-8.71 0,-4.81 3.9,-8.72 8.72,-8.72 4.8,0 8.71,3.91 8.71,8.72 0,4.38 3.55,7.94 7.94,7.94 4.38,0 7.93,-3.56 7.93,-7.94 0,-10.77 -6.99,-19.83 -16.65,-23.16l0 -4.51c0,-4.38 -3.54,-7.94 -7.93,-7.94 -4.38,0 -7.94,3.56 -7.94,7.94l0 4.51c-9.65,3.33 -16.65,12.39 -16.65,23.16 0,13.56 11.03,24.58 24.59,24.58 4.8,0 8.71,3.91 8.71,8.74 0,4.79 -3.91,8.7 -8.71,8.7zm-135.7 22.25l79.36 0c4.38,0 7.93,-3.57 7.93,-7.94 0,-4.37 -3.55,-7.93 -7.93,-7.93l-79.36 0c-4.38,0 -7.93,3.56 -7.93,7.93 0,4.37 3.55,7.94 7.93,7.94zm0 -79.36l79.36 0c4.38,0 7.93,-3.56 7.93,-7.93 0,-4.39 -3.55,-7.94 -7.93,-7.94l-79.36 0c-4.38,0 -7.93,3.55 -7.93,7.94 0,4.37 3.55,7.93 7.93,7.93zm0 39.67l79.36 0c4.38,0 7.93,-3.55 7.93,-7.94 0,-4.38 -3.55,-7.93 -7.93,-7.93l-79.36 0c-4.38,0 -7.93,3.55 -7.93,7.93 0,4.39 3.55,7.94 7.93,7.94z"
                                                                        />
                                                                      </svg>
                                                                </span>
                                                                Funding Amount
                                                            </span>
                                                            <span
                                                                className={fundingAmountDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                            {
                                                                fundingAmountDropdown === true ?
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={2} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 12h14"/>
                                                                    </svg>
                                                                    :
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={1.5} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 5.75v12.5"/>
                                                                        <path d="M18.25 12H5.75"/>
                                                                    </svg>
                                                            }
                                                        </span>
                                                        </div>
                                                        {
                                                            fundingAmountDropdown &&
                                                            <div
                                                                className={fundingAmountDropdown ? "custom-dropdown-item mt-3 bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">
                                                                        <Box sx={{minWidth: 120}}>
                                                                            <FormControl fullWidth>
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder="Enter Funding Amount"
                                                                                        className="form-control month-input"
                                                                                        id="outlined-basic2"
                                                                                        value={fundingAmount}
                                                                                        onChange={(e) => setFundingAmount(e.target.value)}
                                                                                    />
                                                                                    <label htmlFor="outlined-basic2">Enter
                                                                                        Funding Amount</label>
                                                                                </div>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>

                                                    <li className="border-bottom pb-2 text-dark">
                                                        <div
                                                            onClick={() => setCompanyLocationDropdown(!companyLocationDropdown)}
                                                            className={companyLocationDropdown === true ? "custom-dropdown position-relative custom-active cursor" : "custom-dropdown position-relative cursor"}>
                                                            <span
                                                                className={companyLocationDropdown === true ? "text-primary " : ""}>
                                                                <span className="me-2">
                                                                    <svg width={22} version="1.1" viewBox="0 0 64 64"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                    <g>
                                                                      <g id="Icon-Location"
                                                                         transform="translate(232.000000, 376.000000)">
                                                                        <path className="st0" fill="#134563"
                                                                              d="M-200-320.3l-0.9-1.1c-0.6-0.8-15.9-18.7-15.9-29.4c0-9.3,7.6-16.8,16.8-16.8 s16.8,7.6,16.8,16.8c0,10.7-15.3,28.7-15.9,29.4L-200-320.3L-200-320.3z M-200-365.3c-8,0-14.4,6.5-14.4,14.4 c0,8.4,11.1,22.7,14.4,26.8c3.3-4.1,14.4-18.3,14.4-26.8C-185.6-358.8-192-365.3-200-365.3L-200-365.3z"
                                                                              id="Fill-55"/>
                                                                        <path className="st0" fill="#134563"
                                                                              d="M-200-344.4c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4S-196.5-344.4-200-344.4 L-200-344.4z M-200-354.8c-2.2,0-4,1.8-4,4s1.8,4,4,4c2.2,0,4-1.8,4-4S-197.8-354.8-200-354.8L-200-354.8z"
                                                                              id="Fill-56"/>
                                                                      </g>
                                                                    </g>
                                                                  </svg>
                                                                </span>
                                                                Company Location
                                                            </span>
                                                            <span
                                                                className={companyLocationDropdown === true ? "chevron-active position-absolute end-0" : "chevron position-absolute end-0"}>
                                                            {
                                                                companyLocationDropdown === true ?
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={2} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 12h14"/>
                                                                    </svg>
                                                                    :
                                                                    <svg width={20} height={20} fill="none"
                                                                         stroke="#000"
                                                                         strokeLinecap="round" strokeLinejoin="round"
                                                                         strokeWidth={1.5} viewBox="0 0 24 24"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 5.75v12.5"/>
                                                                        <path d="M18.25 12H5.75"/>
                                                                    </svg>
                                                            }
                                                        </span>
                                                        </div>
                                                        {
                                                            companyLocationDropdown &&
                                                            <div
                                                                className={companyLocationDropdown ? "custom-dropdown-item mt-3 bg-off-white p-3 border-top border-primary" : "d-none "}>
                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">
                                                                        <Box sx={{minWidth: 120}}>
                                                                            <FormControl fullWidth>
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Enter Company Location"
                                                                                        className="form-control month-input"
                                                                                        id="outlined-basic2"
                                                                                        value={companyLocation}
                                                                                        onChange={(e) => setCompanyLocation(e.target.value)}
                                                                                    />
                                                                                    <label htmlFor="outlined-basic2">Enter
                                                                                        Company Location</label>
                                                                                </div>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>
                                                </>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>

                            {/* <button className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-root MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium w-50 guid-btn me-4 css-180lf2n-MuiButtonBase-root-MuiButton-root" tabindex="0" type="button">Run<span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button> */}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default SideBar