import React from "react";
import styled from "styled-components";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import {DASHBOARD_BACKEND_PATH} from "./config/constants";

function LinkedInPage2() {
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoginCalled, setIsLoginCalled] = React.useState(false);

  const { linkedInLogin } = useLinkedIn({
    clientId: "787kg6pxs8afui",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      if (!isLoginCalled) {
        setIsLoginCalled(true);
        console.log(code);
        setCode(code);
        setErrorMessage("");

        fetch(`${DASHBOARD_BACKEND_PATH}/linked`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "authcode": code
          })
        })
          .then(res => res.json())
          .then((response) => {
            if (
              response.firstname !== "" &&
              response.firstname !== null &&
              response.firstname !== undefined &&
              response.lastname !== "" &&
              response.lastname !== undefined &&
              response.lastname !== null &&
              response.email !== "" &&
              response.email !== null &&
              response.email !== undefined
            ) {
              var first = response.firstname;
              var email = response.email;
              fetch(`${DASHBOARD_BACKEND_PATH}/auth/SignUp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  "name": `${response.firstname} ${response.lastname}`,
                  "id": "",
                  "number": "",
                  "email": response.email,
                  "password": "null",
                  "type": "linkedin"
                })
              })
                .then(res => res.json())
                .then((responsee) => {
                  const id = responsee.user.id;
                  if (responsee.message === "Successfull") {
                    localStorage.setItem('id', id);
                    localStorage.setItem('email', responsee.user.email);
                    localStorage.setItem('number', responsee.user.number);
                    localStorage.setItem('name', responsee.user.name);
                    window.location.reload(false);
                  } else {
                    alert("Failed");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
      setCode("");
      setErrorMessage(error.errorMessage);

      if (error.serviceErrorCode === 65601) {
        // Handle the case where the user has revoked access
        // Show an error message or take appropriate action
      }
    },
  });

  return (
    <Wrapper>
  <div className="sign-in-social-tag3" style={{cursor:"pointer"}} onClick={linkedInLogin}>
                <i className="fa fa-linkedin me-2 mt-n1 text-primary" aria-hidden="true" />Sign in with LinkedIn
                </div>
      {/* {!code && <div>No code</div>}
      {code && (
        <div>
          <div>Authorization Code: {code}</div>
          <div>
            Follow{" "}
            <Link
              target="_blank"
              href="https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fconsumer%2Fcontext&tabs=HTTPS#step-3-exchange-authorization-code-for-an-access-token"
              rel="noreferrer"
            >
              this
            </Link>{" "}
            to continue
          </div>
        </div>
      )}
      {errorMessage && <div>{errorMessage}</div>} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 3px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled.a`
  font-size: 20px;
  font-weight: bold;
`;

export default LinkedInPage2;


