import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom'
import {ApplicationContext} from "../context";

export default function ProfileTrigger({triggers}) {
    const navigate = useNavigate();
    const {dispatch} = useContext(ApplicationContext);
    return (
        <div>
            <table className='set-table-1'>
                <tbody>
                <tr>
                    <th></th>
                    <th>Saved Trigger(s)</th>
                    {/* <th>Location</th> */}
                </tr>
                </tbody>
                {triggers?.map((trigger, index) => {
                    return (
                        <tr onClick={() => {
                            dispatch({type: 'PROCESS_DATA_LIST', payload: {type: null, data: []}})
                            dispatch({type: 'SET_IS_LOADING', payload: true})
                            navigate(`/userprofile?type=profileTriggerType&name=${trigger?.name}&location=${trigger?.location}`);
                        }}>
                            <td>{index + 1}</td>
                            <td>{trigger?.name}</td>
                            {/* <td>{trigger?.location}</td> */}
                        </tr>
                    )
                })}
            </table>
        </div>

    )
}
