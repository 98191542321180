import {useReducer} from 'react'
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import Routing from './Routing/Routing';
import {ApplicationContext} from './context';
import {initialState, reducer} from './context/reducer';
import {SCRAPPER_BACKEND_PATH} from "./config/constants";
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const processTriggerRequest = async (type, payload) => {
        dispatch({type: 'SET_IS_LOADING', payload: true})
        let res = await axios.post(`${SCRAPPER_BACKEND_PATH}/data/${type}`, payload);
        res = res.data || {};
        dispatch({type: 'PROCESS_DATA_LIST', payload: {type: type, data: res.data || []}})
        dispatch({type: 'SET_IS_LOADING', payload: false});

        switch (res.errorCode) {
            case 5000: /* No Subscription */
                Swal.fire({
                    title: 'No Subscription!',
                    confirmButtonColor: '#f20086',
                    html:
                        `You don't have any subscription<br/><br/>` +
                        'Please <a href="/billing">click here</a> first to subscribe.',
                    icon: 'error',
                });
                break;
            case 5001: /* Expired */
                Swal.fire({
                    title: 'Package Expired!',
                    confirmButtonColor: '#f20086',
                    html:
                        'Your package has been expired<br/><br/>' +
                        'Please <a href="/billing">click here</a> to renew your package.',
                    icon: 'warning',
                });
                break;
            case 5002: /* Usage Exceeded */
                Swal.fire({
                    title: 'Usage Limit Reached',
                    confirmButtonColor: '#f20086',
                    html:
                        'You have reached your usage limit for the current month.<br/><br/>' +
                        'To upgrade your package ' +
                        '<a href="/billing">click here</a>.',
                    icon: 'warning',
                });
                break;
            case 5003: /* Cancelled */
                Swal.fire({
                    title: 'Subscription Cancelled!',
                    confirmButtonColor: '#f20086',
                    html:
                        'Your subscription has been cancelled <br/><br/>' +
                        'Please <a href="/billing">click here</a> to renew your package.',
                    icon: 'warning',
                });
                break;

        }

        return res;
    }

    const handlePagination = (pageNumber, data = null) => {
        data = data ?? (state.dataList?.data || []);
        const totalPages = Math.ceil((data.length) / 15);
        const PageEnd = (pageNumber + 1) * 15;
        const PageStart = PageEnd - 15;
        const paginArr = data?.length ? data.slice(PageStart, PageEnd) : [];
        dispatch({type: 'PAGINATED_DATA', payload: paginArr});
        dispatch({type: 'SET_TOTAL_PAGES', payload: totalPages});

    }
    return (
        <ApplicationContext.Provider value={{state, dispatch, processTriggerRequest, handlePagination}}>
            {/* <div className="wrapper_container"> */}
            <ToastContainer />
            <Routing/>
            {/* </div> */}
        </ApplicationContext.Provider>
    )
}

export default App;