import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {ApplicationContext} from "../context";

export default function CompanyTrigger({triggers}) {
    const navigate = useNavigate();
    const {dispatch} = useContext(ApplicationContext);
    return (
        <div>
            <table className='set-table-1'>
                <tbody>
                <tr>
                    <th></th>
                    <th>Saved Trigger(s)</th>
                </tr>
                </tbody>
                {triggers?.map((trigger, index) => {
                    const fundingRounds = trigger?.funding_rounds.split(',');
                    return (
                        <tr onClick={() => {
                            dispatch({type: 'PROCESS_DATA_LIST', payload: {type: null, data: []}})
                            dispatch({type: 'SET_IS_LOADING', payload: true})
                            navigate(`/account?type=${'companyTriggerType'}&name=${encodeURIComponent(fundingRounds)}`)
                        }}>
                            <td>{index + 1}</td>
                            <td>{fundingRounds.join(', ')}</td>
                            {/* <td>{trigger?.location}</td> */}
                        </tr>
                    )
                })}
            </table>
        </div>

    )
}
