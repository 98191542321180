import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../DataTable/DataTable'
import FilterationBox from '../FilterationBox/FilterationBox'
import Header from '../Header/Header'
import SemiHeader from '../SemiHeader/SemiHeader'
import SideBar from '../SideBar/SideBar'
import Loading from "../../helpers/Loading";
import { ApplicationContext } from '../../context'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { SCRAPPER_BACKEND_PATH } from "../../config/constants";
import { useSearchParams } from "react-router-dom";
import { TRIGGER_TYPE_PROFILES, TRIGGER_TYPE_JOBS, TRIGGER_TYPE_COMPANIES } from "../../constants";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: 'black',
    maxHeight: '500px',
    overflowY: 'scroll'
}

const TriggerDataPage = ({ page }) => {
    // const { data, error, loading } = useFetch('http://localhost:4000/search/profile', _handleProfileSearch())
    const [userData, setUserData] = useState([]);

    const [profileData, setProfileData] = useState([])
    const [existedSearchFilters, setExistedSearchFilters] = useState({})

    const { processTriggerRequest, handlePagination, dispatch, state } = useContext(ApplicationContext);
    const [search] = useSearchParams();

    useEffect(() => {
        dispatch({
            type: 'PROCESS_DATA_LIST',
            payload: {type: null, data: []}
        })
    }, [])

    useEffect(() => {
        async function fetchData() {
            await handlePagination(0);
            let res;
            if (!state.dataList?.type && search.size > 1) {
                if (search?.get('type') === 'profileTriggerType') {
                    res = await processTriggerRequest(TRIGGER_TYPE_PROFILES, {
                        name: search?.get('name'),
                        location: search?.get('location'),
                        triggerType: 'Recent Role Change',
                        timeOfRole: 7,
                        uid: localStorage.getItem("id")
                    });
                } else if (search?.get('type') === 'jobTriggerType') {
                    res = await processTriggerRequest(TRIGGER_TYPE_JOBS, {
                        name: search?.get('name'),
                        location: search?.get('location'),
                        uid: localStorage.getItem("id")
                    });

                } else if (search?.get('type') === 'companyTriggerType') {
                    let fundingRounds = decodeURIComponent(search.get('name'));
                    fundingRounds = fundingRounds ? fundingRounds.split(',') : [];
                    if (fundingRounds?.length) {
                        res = await processTriggerRequest(TRIGGER_TYPE_COMPANIES, {
                            fundingRounds,
                            uid: localStorage.getItem("id")
                        });
                    }
                }

                if (res.existedSearchFilters) {
                    setExistedSearchFilters(res.existedSearchFilters);
                }
                dispatch({ type: 'SET_IS_LOADING', payload: false });
            }
        }

        fetchData();
    }, [state?.dataList?.data?.length, search]);

    function _handleProfileSearch(obj, location) {
        dispatch({ type: 'SET_IS_LOADING', payload: true })
        if (obj?.triggerType === 'Recent Role Change') {
            axios.post(`${SCRAPPER_BACKEND_PATH}/search/profile`, obj)
                .then(res => {
                    dispatch({ type: 'SET_IS_LOADING', payload: false })
                    console.log("just_checking", res.data.data)
                    console.log("just_testing", res.data)
                    setUserData(res.data.data)
                })
        } else {
            axios.post(`${SCRAPPER_BACKEND_PATH}/search/job`, { name: obj.name, location: location })
                .then(res => {
                    console.log("just_FIRST", res.data)
                    dispatch({ type: 'SET_IS_LOADING', payload: false })
                    setUserData(res.data.data)
                })
        }
    }

    const handleDownload = () => {
        if (state.dataList?.type === TRIGGER_TYPE_PROFILES) {
            var AllData = state?.dataList.data.map(row => ({
                FullName: row.name,
                HeadLine: row.text,
                Profile: row.memberIdentity,
                Company: row.company,
                TimeInRole: row.currentJobStartDate,
                Location: row.location,
            }));
        }else if(state.dataList?.type === TRIGGER_TYPE_COMPANIES){
            var AllData = state?.dataList.data.map(row => ({
                Company: row.company.name,
                FundingRound: row.fundingRound,
                Amount: row.amount,
                AnnouncedDate: row.announcedDate,
                Industries: row.company.industries,
                Location: row.company.location,
                LeadInvestors: row.investors,
            }));
        }else if(state.dataList?.type === TRIGGER_TYPE_JOBS){
            var AllData = state?.dataList.data.map(row => ({
                jobTitle: cleanJobTitle(row.jobTitle),
                postedOn: row.postedOn,
                companyName: row.companyName,
                location: row.location,
                headCountFrom: row.headCountFrom,
                headCountTo: row.headCountTo,

            }));


        }



        console.log('dATAAA', state?.paginatedData);

        const csvData = convertToCSV(AllData);
        console.log(AllData, "data");
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'trigify.csv';
        link.click();
    };

    const cleanJobTitle = (jobTitle) => {
        if (jobTitle && jobTitle.includes(',')) {
            return `"${jobTitle.replace(/"/g, '""')}"`;
        }
        return `"${jobTitle}"`;
    };

    const convertToCSV = (data) => {

        if(state.dataList?.type === TRIGGER_TYPE_PROFILES){
            const header = 'Full Name,Head Line,Profile,Company,Time In Role,Location\n';
            const rows = data.map(row => {
                const FullName = `"${row.FullName}"`;
                const HeadLine = `"${row.HeadLine}"`;
                const Profile = `"${row.Profile}"`;
                const Company = `"${row.Company}"`;
                const TimeInRole = `"${row.TimeInRole}"`;
                const Location = `"${row.Location}"`;


                return `${FullName},${HeadLine},${Profile},${Company},${TimeInRole},${Location}\n`;
            }).join('');
            return header + rows;
        }else if(state.dataList?.type === TRIGGER_TYPE_COMPANIES){
            const header = 'Company,FundingRound,Amount,AnnouncedDate,Industries,Location,LeadInvestors\n';
            const rows = data.map(row => {
                const Company = `"${row.Company}"`;
                const FundingRound = `"${row.FundingRound}"`;
                const Amount = `"${row.Amount}"`;
                const AnnouncedDate = `"${row.AnnouncedDate}"`;
                const Industries = `"${row.Industries}"`;
                const Location = `"${row.Location}"`;
                const LeadInvestors = `"${row.LeadInvestors}"`;


                return `${Company},${FundingRound},${Amount},${AnnouncedDate},${Industries},${Location},${LeadInvestors}\n`;
            }).join('');
            return header + rows;
        }else if(state.dataList?.type === TRIGGER_TYPE_JOBS){
            const header = 'jobTitle,postedOn,companyName,location,headCountFrom,headCountTo\n';
            const rows = data.map(row => {
                const jobTitle = `"${row.jobTitle}"`;
                const postedOn = `"${row.postedOn}"`;
                const companyName = `"${row.companyName}"`;
                const location = `"${row.location}"`;
                const headCountFrom = `"${row.headCountFrom}"`;
                const headCountTo = `"${row.headCountTo}"`;


                return `${jobTitle},${postedOn},${companyName},${location},${headCountFrom},${headCountTo}\n`;
            }).join('');
            return header + rows;
        }

        // const header = 'Job Title,Posted Date,Company Name,Location,Head Count From,Head Count To\n';
        // const rows = data.map(row => {
        //   const jobTitle = row.jobTitle;
        //   const postedOn = `"${row.postedOn}"`;
        //   const companyName = `"${row.companyName}"`;
        //   const location = `"${row.location}"`;
        //   const headCountFrom = `"${row.headCountFrom}"`;
        //   const headCountTo = `"${row.headCountTo}"`;

        //   return `${jobTitle},${postedOn},${companyName},${location},${headCountFrom},${headCountTo}\n`;
        // }).join('');
        // return header + rows;


    };


    if (state.isLoading) {
        return <Loading />
    }
    else {
        return (
            <>
                <Modal
                    open={state.open}
                    onClose={() => dispatch({ type: 'SET_TOGGLE_MODAL', payload: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                                Text in a modal
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </Typography> */}
                        <div
                            dangerouslySetInnerHTML={{ __html: state.jobDetails }}
                        />
                    </Box>
                </Modal>
                <Modal
                    open={state.open}
                    onClose={() => dispatch({ type: 'SET_TOGGLE_MODAL', payload: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                                Text in a modal
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </Typography> */}
                        <div
                            dangerouslySetInnerHTML={{ __html: state.profileHeading }}
                        />
                    </Box>
                </Modal>
                <Header Settrigger="Trigger" Mytrigger="Home" />
                <SemiHeader handleDownload={handleDownload} data='' filename="data" />
                {/* <div className="modal-content" style={{ position: 'relative', left: '50%', top: '50%', zIndex: 9999999 }}>
                            <span className="close-button">&times;</span>
                            <div
                                dangerouslySetInnerHTML={{ __html: detail }}
                            />
                            <div>
                                hello world
                            </div>
                        </div> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 p-0">
                            <SideBar page={page} selectedData={_handleProfileSearch} profilePaginatedUserData={profileData} existedSearchFilters={existedSearchFilters} />
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12 set-col-div-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {/*<FilterationBox />*/}
                                    <DataTable data={userData} />
                                    <div className="set-the-div-1">
                                        <ReactPaginate className="set-button-1"
                                                       breakLabel="..."
                                                       nextLabel=">"
                                                       onPageChange={(e) => {
                                                           handlePagination(e.selected)
                                                       }}
                                                       pageRangeDisplayed={2}
                                                       marginPagesDisplayed={1}
                                                       pageCount={state.totalPages}
                                                       previousLabel="<"
                                                       renderOnZeroPageCount={null}
                                                       activeClassName="active-page"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }




}


export default TriggerDataPage