import React, {useState, useRef, useEffect} from 'react'
import {
    CardElement, useElements, useStripe,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import styles from './style.module.css';
import axios from 'axios';
// import Modal from 'react-modal';
import { ReactComponent as Close } from "../../images/close.svg";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import swal from 'sweetalert';
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
};

export default function StripeCheckout({ open, data, handleClose, selectedPackage }) {
    const [errorMsg, setErrorMsg] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [toggleCard, setToggleCard] = useState(false);
    const [stripeCardId, setCardId] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCardId(data[0]?.id);
        setToggleCard(data.length <= 0);
    }, [data]);

    const paymentHandler = async (e) => {
        e.preventDefault();
        if (toggleCard && (!stripe || !elements)) {
            return;
        }

        setLoading(true);

        if (toggleCard) {
            const paymentMethodResponse = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (paymentMethodResponse.error) {
                swal('Error!', paymentMethodResponse.error.message, "error");
                setLoading(false);
                return;
            }

            setCardId(paymentMethodResponse.paymentMethod.id);
        }


        try {
            const { data } = await axios.post(
                `${DASHBOARD_BACKEND_PATH}/api/payment/made`,
                { uid: localStorage.getItem("id"), package: selectedPackage.id, paymentMethod: stripeCardId }
            );

            swal(data.success ? 'Success!':'Oops!', data.message, data.success ? 'success': 'error').then(() => {
                if (data.success) {
                    window.location.reload();
                }
            });
        }
        catch (e) {
            swal('Error!', e.response.data?.message || "Something went wrong", "error");
        }
        finally {
            setLoading(false);
        }

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form>
                    <div>
                        <h1>Checkout Form</h1>
                        <h4>Plan: ${selectedPackage.price}</h4>
                    </div>
                    <hr/>
                    {/* <ul>
                        {
                            data?.map((ele, index) => <li key={index + 'cardNumber'} onClick={() => {

                                // docs.value = ele.number
                            }}>{ele?.number?.substr(0, 5) + '*****'}</li>)
                        }
                        <li>Add New Account</li>
                    </ul> */}
                    <ol style={{ listStyle: 'none' }}>
                        {
                            data?.map((ele, index) => <li key={index + 'cardNumber'}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                onClick={() => {
                                    console.log('idddss', ele.id)
                                }}>
                                <div>
                                    <input defaultChecked={index === 0} name={`name`} type='radio' onChange={() => {
                                        setToggleCard(false);
                                        setCardId(ele.id);
                                    }} />
                                    <span className={styles.radioBtn}>{`${ele?.card?.brand}`} - ending: {ele?.card?.last4}</span>
                                </div>
                                <span>{`${ele?.card?.exp_month} / ${ele?.card?.exp_year}`}</span>
                            </li>)
                        }
                        {
                            <li><input defaultChecked={!data.length} onChange={() => {
                                setToggleCard(true);
                                // setCardId(ele.id);
                            }} type="radio" name="name" /> <span className={[styles.radioBtn, styles.manualTrans].join(' ')}>Add New Account</span> </li>
                        }

                    </ol>
                    {toggleCard ? <div className={styles.form}>
                        {errorMsg && <div className="errorMsg">{errorMsg}</div>}
                        <CardElement />
                        <button disabled={!stripe || !elements || loading} onClick={paymentHandler} className={styles.btn}>{loading ? 'Please Wait...' : 'Pay Now'}</button>
                    </div> : <button disabled={loading} className={styles.btn2} onClick={paymentHandler}>{loading ? 'Please Wait...' : 'Submit Card'}</button>}
                </form>
            </Box>
        </Modal>
    )
}


