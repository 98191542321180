import React,{useState,useEffect} from 'react'
import SettingTab from '../Tabs/SettingTab'
import swal from 'sweetalert';
import Header from '../Header/Header'
import { Button } from '@mui/material'
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";

const ChangePassword = () => {
const [currentPassword,setCurrentPassword]=useState("")
const [password,setPassword]=useState("")
const [confirmPassword,setConfirmPassword]=useState("")

const Submit=()=>{
    if(currentPassword==""){
        swal("Please Enter Current Password", "", "error");
    }
    else if(password==""){
        swal("Please Enter Password", "", "error");
    }
    else if(confirmPassword==""){
        swal("Please Enter Confirm Password", "", "error");
    }
    else if(password!==confirmPassword){
        swal("Confirm Password Not Matched", "", "error");
    }
    else{
fetch(`${DASHBOARD_BACKEND_PATH}/ChangePassword`,{
    method:"POST",
    headers:{'content-type':'application/json'},
    body:JSON.stringify({
       "email":localStorage.getItem('email'),
       "password":currentPassword,
       "newpassword":password 
    })
}).then(res=>{
    return res.json()
}).then(res=>{
    if(res.message=="Password Changed Successfully"){
        window.location.reload(false)
    }
    else{
        swal("Email Or Password Not Found", "", "error");
    }
})
    }
}
    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 bg-white rounded-1 p-4">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <span className='text-dark'><b>Current Password</b></span>
                                <input onChange={(e)=>setCurrentPassword(e.target.value)} type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <span className='text-dark'><b>New Password</b></span>
                                <input onChange={(e)=>setPassword(e.target.value)} type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <span className='text-dark'><b>Confirm Password</b></span>
                                <input onChange={(e)=>setConfirmPassword(e.target.value)} type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 text-end">
                                <Button onClick={()=>Submit()} variant="outlined" color="primary" className='me-3'>Save changes</Button>
                                <Button onClick={()=>window.location.reload(false)} variant="outlined" color="warning">Reset</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ChangePassword