import moment from "moment";

export const limitString = (str, maxLength = 60) => {
    if (str?.length > maxLength) {
        return `${str.substring(0, maxLength - 3)}...`;
    }

    return str;
}

export const formatNumberWithCommas = (value) => {
    // Convert number to money format (without currency symbol)

    if (value === null) {
        return null;
    }

    return  new Intl.NumberFormat('en-US').format(value);
}

export const getTimeInRoleFromJobStartDate = (jobStartDate) => {
    if(!jobStartDate)
        return '-';
    [jobStartDate] = (new Date(jobStartDate)).toISOString().split('T');
    const startDate = moment(jobStartDate).startOf('month');

    const currentDate = moment().endOf('month');

    const yearsDiff = currentDate.diff(startDate, 'years');
    const monthsDiff = currentDate.diff(startDate, 'months') % 12;

    const formattedStartDate = startDate.format('MMM YYYY');

    const yearsHeading = yearsDiff ? `${yearsDiff} yrs ` : '';
    const monthsHeading = monthsDiff ? `${monthsDiff} mos ` : '';
    const formattedExperience = yearsHeading + monthsHeading;

    return `${formattedStartDate} - Present  · ${formattedExperience}`;
}