import { Button } from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {ApplicationContext} from "../../context";

const SemiHeader = ({ handleDownload, data, filename}) => {
    const navigate = useNavigate();
    const {state, dispatch} = useContext(ApplicationContext);
    const [total, setTotal] = useState(0);
    const [netNew, setNetNew] = useState(0);

    useEffect(() => {
        const data = state.dataList.data;
        if (data?.length) {
            setTotal(data.length);
            const netNewCount = data.reduce((acc, item) => {
                if (item.is_new) {
                    acc++;
                }
                return acc;
            }, 0);
            setNetNew(netNewCount);
        }
    }, [state.dataList]);

    const handleMenuBarClick = () => {
        dispatch({
            type: 'PROCESS_DATA_LIST',
            payload: {type: null, data: []}
        })
    }





    return (
        <>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-md-4">
                        <div className="semi-header-link">
                            <ul className='d-flex gap-5'>
                                <li className='nav-items'>
                                    <NavLink to='/userprofile' onClick={handleMenuBarClick} className="nav-link text-dark">
                                        <span className="contact-icon me-3">
                                            <svg width={20} height={20} fill="CurrentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 9a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z" />
                                                <path d="M11 12.5a3 3 0 0 0-3 3 1 1 0 0 0 1 1h6a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-2Z" />
                                                <path fillRule="evenodd" d="M7.543 2.883a31.331 31.331 0 0 1 8.913 0 3.196 3.196 0 0 1 2.73 2.874l.126 1.396c.293 3.225.293 6.47 0 9.694l-.127 1.396a3.197 3.197 0 0 1-2.729 2.874 31.334 31.334 0 0 1-8.913 0 3.197 3.197 0 0 1-2.728-2.874l-.127-1.396a53.504 53.504 0 0 1 0-9.694l.127-1.396a3.197 3.197 0 0 1 2.728-2.874Zm8.7 1.484a29.832 29.832 0 0 0-8.486 0 1.697 1.697 0 0 0-1.448 1.526l-.127 1.396a52.003 52.003 0 0 0 0 9.422l.127 1.396c.07.783.67 1.414 1.448 1.526a29.86 29.86 0 0 0 8.486 0 1.696 1.696 0 0 0 1.448-1.526l.127-1.396a52.009 52.009 0 0 0 0-9.422l-.127-1.396a1.697 1.697 0 0 0-1.448-1.526Z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        Contacts
                                    </NavLink>
                                </li>
                                <li className='nav-items'>
                                    <NavLink to='/account' onClick={handleMenuBarClick} className="nav-link text-dark">
                                        <span className="account-icon me-3">

                                            <svg width={20} height={20} fill="CurrentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 2H9c-1.103 0-2 .897-2 2v6H5c-1.103 0-2 .897-2 2v9a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2ZM5 12h6v8H5v-8Zm14 8h-6v-8c0-1.103-.897-2-2-2H9V4h10v16Z" />
                                                <path d="M11 6h2v2h-2V6Zm4 0h2v2h-2V6Zm0 4.031h2V12h-2v-1.969ZM15 14h2v2h-2v-2Zm-8 .001h2v2H7v-2Z" />
                                            </svg></span> Accounts</NavLink>
                                </li>
                            </ul>


                        </div>

                    </div>
                    <div className="col-md-8 d-flex flex-row align-items-baseline gap-2 justify-content-end text-end">
                        <ul className='d-flex gap-3 align-items-center justify-content-end'>
                            <li className='nav-items'>
                                <span className="text-purple fs-12">Total ({total})</span>
                            </li>
                            <li className='nav-items'>
                                <span className="text-purple fs-12">Net New ({netNew})</span>
                            </li>
                        </ul>
                        {/* <div className="semi-header-buttons">
                            <Button variant='outlined' color="primary">Recent Activity</Button>
                        </div> */}
                        {
                            total > 0 &&
                            <div className="semi-header-buttons">
                                <Button onClick={handleDownload} data={data} filename={filename}   variant='outlined' color="primary">Export CSV</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SemiHeader