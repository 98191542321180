import axios from "axios";

export const initialState = {
    todoList: [],
    isLoading: false,
    open: false,
    jobDetails: '',
    dataList: {type: null, data: []},
    errorBoundaryModal: false,
    errorMessage: '',
    profileHeading: '',
    timeInRoleFilterApplied: false,
    paginatedData: [],
    totalPages: 0
};

const constants = {
    SET_IS_LOADING: "SET_IS_LOADING",
    SET_TOGGLE_MODAL: "SET_TOGGLE_MODAL",
    SET_JOB_DETAIL: "SET_JOB_DETAIL",
    PROCESS_DATA_LIST: 'PROCESS_DATA_LIST',
    ERROR_BOUNDARY: 'ERROR_BOUNDARY',
    ERROR_MESSAGE: 'ERROR_MESSAGE',
    SEEDING_TYPE: 'SEEDING_TYPE',
    SET_PROFILE_HEADING : 'SET_PROFILE_HEADING',
    SET_TIME_IN_ROLE_FILTER_APPLIED : 'SET_TIME_IN_ROLE_FILTER_APPLIED',
    PAGINATED_DATA : 'PAGINATED_DATA',
    SET_TOTAL_PAGES : 'SET_TOTAL_PAGES',
};

//Reducer to Handle Actions
export const reducer = (state, action) => {
    switch (action.type) {
        //   case constants.SET_IS_LOADING:
        //     return {
        //       todoList: [
        //         ...state.todoList,
        //         {
        //           id: new Date().valueOf(),
        //           label: action.todoItemLabel,
        //           completed: false
        //         }
        //       ]
        //     };
        case constants.SET_IS_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        case constants.SET_TOGGLE_MODAL: {
            return { ...state, open: action.payload };
        }
        case constants.SET_JOB_DETAIL: {
            return { ...state, jobDetails: action.payload };
        }
        case constants.PROCESS_DATA_LIST: {
            return { ...state, dataList: action.payload }
        }
        case constants.ERROR_BOUNDARY: {
            return { ...state, errorBoundaryModal: action.payload }
        }
        case constants.ERROR_MESSAGE: {
            return { ...state, errorMessage: action.payload }
        }
        case constants.SET_PROFILE_HEADING: {
            return { ...state, profileHeading: action.payload }
        }
        case constants.SET_TIME_IN_ROLE_FILTER_APPLIED: {
            return { ...state, timeInRoleFilterApplied: action.payload }
        }
        case constants.PAGINATED_DATA: {
            return { ...state, paginatedData: action.payload }
        }
        case constants.SET_TOTAL_PAGES: {
            return { ...state, totalPages: action.payload }
        }
        default:
            return state;
    }
};