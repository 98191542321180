import { React, useState } from 'react'
import './email.css'
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";

const Email = () => {
    const [email, setemail] = useState("");
    const [tab1, settab1] = useState(true);

    const navigate= useNavigate()
    const SendEmail = () => {
        if (email == "") {
            // alert("Please Enter email field")
            swal("Enter Email", "", "error");
        }else{
            
            fetch(`${DASHBOARD_BACKEND_PATH}/GetEmail`,{
                method:"POST",
                headers:{'content-type':'application/json'},
                body:JSON.stringify({
                    "email":email
                })
            }).then(res=>{
                return res.json()
            }).then(ress=>{
                if(ress.message=='Otp Send'){
                    localStorage.setItem('resetemail',email)
                    navigate('/otp')
                }
                else{
                    swal("Email Not Found", "", "error");
                }
            })
        }   
        
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 login-bg">
                        <div className="container border rounded-1 box-login-shadow login-container bg-white p-4">
                            <div className="row pb-3">
                                <div className="col-md-12 ">
                                    <div className="tab-heading d-flex align-items-center justify-content-around gap-5">
                                      

                                      
                                        {/* <h5
                                            onClick={() => {
                                                settab1(true)
                                                settab2(false)
                                            }}
                                            className={tab1 === true ? "text-dark cursor fw-normal tab-active fs-14" : " text-dark cursor fw-normal fs-14"}>Login</h5>
                                        <h5
                                            onClick={() => {
                                                settab2(true)
                                                settab1(false)
                                            }}
                                            className={tab2 === true ? "text-dark cursor fw-normal tab-active fs-14" : " text-dark cursor fw-normal fs-14"}>Sign Up</h5>
                                    */}
                                    <h5 className='set-h5-1'>Enter Email Address</h5>
                                   
                                    </div>

                                </div>
                            </div>
                            {/* <div className={tab2 === true ? "custom-border-right" : "custom-border-left"}></div> */}
                            <div className="row mt-2 ">
                                <div className="col-md-12 text-center ">
                                    <div className={tab1 === true ? "login-tab" : "d-none "} >
                                        {/* <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                    <span className="me-2">
                                                        <img src={google} className='img-fluid' alt="" />
                                                    </span>
                                                    Log in with Google
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                    <span className="me-2">
                                                        <img src={facebook} className='img-fluid' alt="" />
                                                    </span>
                                                    Log in with Facebook
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                <LinkedInPage2 />
                                                </button>
                                            </div>
                                        </div> */}
                                        
                                        {/* <div className="row justify-content-center align-items-center">
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                            <div className="col-md-4">
                                                <span className="text-dark border-color-grey">OR</span>
                                            </div>
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                        </div> */}
                                        { <div className="row">
                                            <div className="col-md-12 text-start mb-3 p-0">
                                                <span className='text-dark fs-14'>Email</span>
                                                <input
                                                    placeholder='Email'
                                                    onChange={(e) => setemail(e.target.value)}
                                                    type="email" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div> }
                                        {/* <div className="row">
                                            <div className="col-md-12 text-start mb-3 p-0">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>New Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end ">
                                                        {
                                                            showNewPassword === true ?
                                                                <span
                                                                    onClick={() => setShowNewPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowNewPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            Reset()
                                                        }
                                                    }}
                                                    placeholder='New Password'
                                                    onChange={(e) => setnewpassword(e.target.value)}
                                                    type={showNewPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3 p-0">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Confirm Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end ">
                                                        {
                                                            showConfirmPassword === true ?
                                                                <span
                                                                    onClick={() => setShowConfirmPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowConfirmPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            Reset()
                                                        }
                                                    }}
                                                    placeholder='Confirm Password'
                                                    onChange={(e) => setconfirmpassword(e.target.value)}
                                                    type={showConfirmPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <button
                                                    onClick={()=>SendEmail()} 
                                                    className="login-btn p-1 mt-2 w-100 rounded-1 text-white">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-12 text-center my-3">
                                                <div className="form-check text-center me-2 mt-2">
                                                    <input className="" type="checkbox" value="" defaultChecked />
                                                    <span className='dark-grey fs-14'>  Keep me signed in</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="row">
                                            <div className="col-md-12 text-center">
                                                <p className='text-dark fs-14'>Forgot your password? <Link to='/reset'>Reset It Here</Link> </p>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className={tab2 === true ? "signup-tab" : "d-none"}>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                    <span className="me-2">
                                                        <img src={google} className='img-fluid' alt="" />
                                                    </span>
                                                    Log in with Google
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                    <span className="me-2">
                                                        <img src={facebook} className='img-fluid' alt="" />
                                                    </span>
                                                    Log in with Facebook
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Name</span>
                                                <input
                                                    onChange={(e) => setnameregister(e.target.value)}
                                                    placeholder='Name'
                                                    type="text" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Mobile Number</span>
                                                <input
                                                    onChange={(e) => setnumberregister(e.target.value)}
                                                    placeholder='Mobile Number'
                                                    type="number" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Email</span>
                                                <input
                                                    onChange={(e) => setemailregister(e.target.value)}
                                                    placeholder='Email'
                                                    type="email" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        {
                                                            showPassword === true ?
                                                                <span
                                                                    onClick={() => setShowPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input onChange={(e) => setpasswordregister(e.target.value)} placeholder='Password...' type={showPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Confirm Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        {
                                                            showCPassword === true ?
                                                                <span
                                                                    onClick={() => setShowCPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowCPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input onChange={(e) => setconpasswordregister(e.target.value)} placeholder=' Confirm Password...' type={showCPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button onClick={() => Registered()} className="login-btn p-1 mt-2 w-100 rounded-1 text-white">
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 bg-primary right-bg">
                    </div>
                </div>
            </div>
        </>
    )
}

export default Email