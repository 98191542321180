import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import SettingTab from '../Tabs/SettingTab';

const Guide = () => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://scrapper.paybot.cloud/api/dashboard/guide')
            .then((response) => response.json())
            .then((data) => {
                setMessage(data.message);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 bg-white p-4 rounded-1">
                        {/* <h1>Guide</h1> */}
                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: message }} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Guide;