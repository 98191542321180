import React, {useEffect, useState} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SettingTab from '../Tabs/SettingTab';
import Header from '../Header/Header'
import axios from "axios";
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";
import {getFormattedDate} from "../../helpers/date.helper";
const Transactions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    useEffect(() => {
        axios.get(`${DASHBOARD_BACKEND_PATH}/transactions`, {
            headers: {uid: localStorage.getItem("id")}
        }).then(res => {
            res = res.data;
            if (res.success && res.subscriptions?.length) {
                setSubscriptions(res.subscriptions);
            }
        })
    }, [])

    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 bg-white p-4 rounded-1">
                        <div className="row">
                            <div className="col-md-12">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Package</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                                <TableCell align="right">Transaction Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                subscriptions.map(subscription => (
                                                    <TableRow

                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {subscription.package_name}
                                                        </TableCell>
                                                        <TableCell align="right">${subscription.purchase_amount}</TableCell>
                                                        <TableCell align="right">{getFormattedDate(subscription.purchased_at, true)}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Transactions