import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from '../Header/Header'
import { NavLink } from 'react-router-dom';
import sales from '../../img/sales-force.png'
import hubspot from '../../img/hubspot.jpg'
import reply from '../../img/reply.png'
import slack from '../../img/slack.png'
import smart from '../../img/smart.avif'
import sheet from '../../img/sheets.png'
import SettingTab from '../Tabs/SettingTab';
const Setting = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    return (
        <>
            <Header billing="Billing" account="Account information" home="Home" />
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9"></div>
                </div>
            </div>
        </>
    )
}

export default Setting