import './Datatable.css';
import React, { useState, useEffect, useContext } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loading from "../../helpers/Loading";
import { ApplicationContext } from '../../context';
import {limitString, formatNumberWithCommas, getTimeInRoleFromJobStartDate} from "../../helpers/string.helper";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {TRIGGER_TYPE_COMPANIES, TRIGGER_TYPE_JOBS, TRIGGER_TYPE_PROFILES} from "../../constants";

const displayHeadCountText = (from, to) => {
    if (!from && !to)
        return '-';

    if (from && !to)
        return `${formatNumberWithCommas(from)}+ employees`;

    return `${formatNumberWithCommas(from)} - ${formatNumberWithCommas(to)} employees`;
}

const DataTable = ({ data, profilePaginatedUserData }) => {
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const { state, dispatch } = useContext(ApplicationContext);

    const handleChange = (e) => {
        setChecked(!checked)
    }

    if (state.paginatedData?.length && state.dataList?.type === TRIGGER_TYPE_COMPANIES) {
        return (
            <>
                {isLoading && <Loading />}

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Company
                                </TableCell>

                                <TableCell align="center">Funding Round</TableCell>
                                <TableCell align="center">Amount</TableCell>
                                <TableCell align="center">Announced Date</TableCell>
                                <TableCell align="center">Industries</TableCell>
                                <TableCell align="center">Location</TableCell>
                                <TableCell align="center">Lead Investors</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                state.paginatedData?.map((item, key) => {
                                    return (
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" className="cursor-pointer" onClick={() => {
                                                if (item.company?.domain) {
                                                    window.open(`https://${item.company?.domain}`, '_blank');
                                                }
                                            }}>
                                                {item.is_new === 1 && <span className="new"></span>}
                                                {item.company?.name}
                                            </TableCell>
                                            <TableCell align="center">{item.fundingRound}</TableCell>
                                            <TableCell align="center">{item.currency} {item.amount}</TableCell>
                                            <TableCell align="center">{item.announcedDate}</TableCell>
                                            <TableCell align="center" onClick={() => {
                                                dispatch({ type: 'SET_PROFILE_HEADING', payload: item.company?.industries?.join(', ') });
                                                dispatch({ type: 'SET_TOGGLE_MODAL', payload: true });
                                            }}>{limitString(item.company?.industries?.join(', '))}</TableCell>
                                            <TableCell align="center" onClick={() => {
                                                dispatch({ type: 'SET_PROFILE_HEADING', payload: item.company?.location });
                                                dispatch({ type: 'SET_TOGGLE_MODAL', payload: true });
                                            }}>{limitString(item.company?.location)}</TableCell>
                                            <TableCell align="center" onClick={() => {
                                                dispatch({ type: 'SET_PROFILE_HEADING', payload: item.investors });
                                                dispatch({ type: 'SET_TOGGLE_MODAL', payload: true });
                                            }}>{limitString(item.investors)}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
    if (state.paginatedData?.length && state.dataList?.type === TRIGGER_TYPE_JOBS) {
        return (
            <>
                {isLoading && <Loading />}

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>

                            <TableRow>
                                <TableCell align="center">
                                    Job Title
                                </TableCell>
                                <TableCell align="center">Posted Date</TableCell>
                                <TableCell align="center">Company Name</TableCell>
                                <TableCell align="center">Location</TableCell>
                                <TableCell align="center">Head Count</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">View Job Post</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                state.paginatedData?.map((job, key) => {
                                    return (
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">
                                                {job.is_new === 1 && <span className="new"></span>}
                                                {job.jobTitle}</TableCell>
                                            <TableCell align="center">{job.postedOn}</TableCell>
                                            <TableCell align="center">{job.companyName}</TableCell>
                                            <TableCell align="center">{job.location}</TableCell>
                                            <TableCell align="center">{displayHeadCountText(job.headCountFrom, job.headCountTo)}</TableCell>
                                            <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => {
                                                dispatch({ type: 'SET_PROFILE_HEADING', payload: job.jobDescription });
                                                dispatch({ type: 'SET_TOGGLE_MODAL', payload: true });
                                            }}>{limitString(job.jobDescription)}</TableCell>
                                            <TableCell align="center">
                                                <a className="custom-icon-button" href={`https://www.linkedin.com/jobs/view/${job.jobId}/`} target="_blank">
                                                    <LinkedInIcon />
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
    if (state.paginatedData?.length && state.dataList?.type === TRIGGER_TYPE_PROFILES) {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Full Name
                                </TableCell>
                                {/*<TableCell align="center">Email</TableCell>*/}
                                {/*<TableCell align="center">Phone Number</TableCell>*/}
                                <TableCell align="center">HeadLine</TableCell>
                                <TableCell align="center">Profile</TableCell>
                                <TableCell align="center">Company</TableCell>
                                {state.timeInRoleFilterApplied && <TableCell align="center">Time In Role</TableCell>}
                                <TableCell align="center">Location</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.paginatedData?.map((item, key) => {
                                    return (
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">
                                                {item.is_new === 1 && <span className="new"></span>}
                                                {item?.name}</TableCell>
                                            {/*<TableCell align="center">{item?.email === '' || item?.email === 'undefined' ? '---' : item?.email}</TableCell>*/}
                                            {/*<TableCell align="center">{item?.phoneNumber === 'undefined' || item?.phoneNumber == 0 || item?.phoneNumber === '' ? '---' : item?.phoneNumber }</TableCell>*/}
                                            <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => {
                                                if (item?.text?.length > 35) {
                                                    dispatch({ type: 'SET_PROFILE_HEADING', payload: item?.text });
                                                    dispatch({ type: 'SET_TOGGLE_MODAL', payload: true });

                                                }
                                            }}>{limitString(item?.text)}</TableCell>
                                            <TableCell align="center" style={{ cursor: 'pointer' }} onClick={() => {
                                                if (item?.memberIdentity) {
                                                    //    <a href={`https://www.linkedin.com/in/${item?.memberIdentity}`} target='_blank' />
                                                    window.open(`https://www.linkedin.com/in/${item?.memberIdentity}`, '_blank');
                                                    //    <a href={`https://www.linkedin.com/in/${item?.memberIdentity}`} target='_blank' />
                                                }
                                            }}>{item?.memberIdentity ? item?.memberIdentity : '---'}</TableCell>
                                            <TableCell align="center">{item?.company == '' || item?.company == 'undefined' ? '---' : item?.company}</TableCell>
                                            {state.timeInRoleFilterApplied && (
                                                    <TableCell align="center">{item?.currentJobStartDate == '' || item?.currentJobStartDate == 'undefined' ? '---' : getTimeInRoleFromJobStartDate(item?.currentJobStartDate)}</TableCell>
                                                )
                                            }

                                            <TableCell align="center">{item?.location ? item?.location : '---'}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*
                <Divider />

                <Table
                    className='box-shadow p-3 rounded'
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data}
                /> */}
            </>
        )
    }
    return (
        <>
            <TableContainer component={Paper}>
                <h4 className="py-2 text-center">No Data Available!</h4>
            </TableContainer>
        </>
    )
}

export default DataTable