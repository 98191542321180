import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { DotLoader  } from "react-spinners"

export default function CircularColor() {
  return (
    <div style={{ position: 'absolute', height: '100%', width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',background: 'rgba(255,255,255,0.5)' }}>
      <p style={{color: '#f20086'}}>We are now scanning billion's of data points</p>
        <DotLoader color="#E62E85" />
    </div>
  );
}