import React from 'react'
import { NavLink } from 'react-router-dom'

const SettingTab = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="setting-tab bg-white text-dark rounded mt-5 p-4">
                    <ul className='d-flex flex-column gap-4 p-0'>
                        <li><NavLink to='/general' className="nav-link text-decoration-none w-fit-content">General</NavLink></li>
                        <li><NavLink to='/changepassword' className="nav-link text-decoration-none w-fit-content">Change Password</NavLink></li>
                        {/* <li><NavLink to='/integration' className="nav-link text-decoration-none">Integrations</NavLink></li> */}
                        <li><NavLink to='/billing' className="nav-link text-decoration-none w-fit-content">Manage Plan</NavLink></li>
                        <li><NavLink to='/transactions' className="nav-link text-decoration-none w-fit-content">Transactions</NavLink></li>
                        {/*<li><NavLink to='/user' className="nav-link text-decoration-none w-fit-content">User</NavLink></li>*/}
                        <li><NavLink to='/guide' className="nav-link text-decoration-none w-fit-content">Guide</NavLink></li>
                    </ul>
                </div>

            </div>
        </>
    )
}

export default SettingTab