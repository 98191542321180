import { Button } from '@mui/material'
import React,{useState,useEffect} from 'react'
import swal from 'sweetalert';
import Header from '../Header/Header'
import SettingTab from '../Tabs/SettingTab'
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";

const General = () => {
    const [firstName,setFirstName]=useState("")
const [surName,setSurName]=useState("")
const [workEmail,setWorkEmail]=useState("")
const [phoneNumber,setPhoneNumber]=useState("")

const [data,setdata]=useState([])
useEffect(()=>{
    fetch(`${DASHBOARD_BACKEND_PATH}/getgeneralsettings/${localStorage.getItem('id')}`,{
        method:"GET",
        headers: {'content-type':'application/json'}
    }).then(res=>{
        return res.json()
    }).then(res=>{
        console.log(res)
        var temp=res.data
        setdata([...temp])
    })
},[])

const Submit=()=>{
    if(data.length>0){
        fetch(`${DASHBOARD_BACKEND_PATH}/generalsettingsUpdate`,{
            method:"POST",
            headers:{'content-type':'application/json'},
            body:JSON.stringify({
               "first_name":firstName==""?data[0].first_name:firstName,
               "sur_name":surName==""?data[0].sur_name:surName,
               "work_email":workEmail==""?data[0].work_email:workEmail,
               "phone_number":phoneNumber==""?data[0].phone_number:phoneNumber,
               "id":data[0].id
            })
        }).then(res=>{
            return res.json()
        }).then(res=>{
            if(res.message=="Successfull"){
                window.location.reload(false)
            }
            else{
                alert("Failed")
            }
        })
    }
    else{
    if(firstName==""){
        swal("Please Enter First Name", "", "error");
    }
    else if(surName==""){
        swal("Please Enter Sur Name", "", "error");
    }
    else if(workEmail==""){
        swal("Please Enter Work Email", "", "error");
    }
    else if(phoneNumber==""){
        swal("Please Enter Phone Number", "", "error");
    }
    else{
fetch(`${DASHBOARD_BACKEND_PATH}/generalsettings`,{
    method:"POST",
    headers:{'content-type':'application/json'},
    body:JSON.stringify({
        "user_id":localStorage.getItem('id'),
       "first_name":firstName,
       "sur_name":surName,
       "work_email":workEmail ,
       "phone_number":phoneNumber
    })
}).then(res=>{
    return res.json()
}).then(res=>{
    if(res.message=="Successfull"){
        window.location.reload(false)
    }
    else{
        alert("Failed")
    }
})
    }
}
}
    return (
        <>
            {/* <Header billing="Billing" account="Account information" home="Home" /> */}
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 bg-white p-4 rounded-1">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <span className='text-dark'><b>First Name</b></span>
                                <input defaultValue={data&&data.length>0?data[0].first_name:""} onChange={(e)=>setFirstName(e.target.value)} type="text" placeholder='Enter here' className="form-control" />
                            </div>
                            <div className="col-md-6 mb-4">
                                <span className='text-dark'><b>Surname</b></span>
                                <input defaultValue={data&&data.length>0?data[0].sur_name:""} onChange={(e)=>setSurName(e.target.value)} type="text" placeholder='Enter here' className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <span className='text-dark'><b>Work email</b></span>
                                <input defaultValue={data&&data.length>0?data[0].work_email:""} onChange={(e)=>setWorkEmail(e.target.value)} type="text" placeholder='Enter here' className="form-control" />
                            </div>
                            <div className="col-md-6 mb-4">
                                <span className='text-dark'><b>Phone number</b></span>
                                <input defaultValue={data&&data.length>0?data[0].phone_number:""} onChange={(e)=>setPhoneNumber(e.target.value)} type="number" placeholder='Enter here' className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-end">
                                {/* <button className="btn btn-primary me-3 rounded-1"></button> */}
                                <Button onClick={()=>Submit()} variant="outlined" color="primary" className='me-3'>Save changes</Button>
                                <Button onClick={()=>window.location.reload(false)} variant="outlined" color="warning">Reset</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default General