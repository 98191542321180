import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../Components/HomePage/HomePage';
import Login from '../Components/Login/Login';
import Email from '../Components/ResetEmail/email';
import Reset from '../Components/ResetPassword/reset';
import Setting from '../Components/Setting/Setting';
import TriggerDataPage from '../Components/TriggerDataPage/TriggerDataPage';
import General from '../Components/General/General';
import Guide from '../Components/Guide/Guide';
import ChangePassword from '../Components/ChangPassword/ChangePassword';
import Integration from '../Components/Integration/Integration';
import Billing from '../Components/Billing/Billing';
import Transactions from '../Components/Transactions/Transactions';
import User from '../Components/User/User';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Otp from '../Components/ResetOtp/otp';
const Routing = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                {/* <Route exact path="/linkedin" component={LinkedInCallback} /> */}
                <Route path='/linkedin' element={<LinkedInCallback />} />
                    {
                        localStorage.getItem("email") === null || localStorage.getItem("email") === undefined || localStorage.getItem("email") === "" ?
                            <>
                                <Route path='/' element={<Login />} />
                                <Route path='/email' element={<Email />} />
                                <Route path='/reset' element={<Reset />} />
                                <Route path='/otp' element={<Otp />} />
                                
                            </>
                            :
                            <>
                                <Route path='/' element={<HomePage />} />
                                <Route path='/userprofile' element={<TriggerDataPage page='userprofile' />} />
                                <Route path='/setting' element={<Setting />} />
                                <Route path='/account' element={<TriggerDataPage page='account' />} />
                                <Route path='/general' element={<General />} />
                                <Route path='/guide' element={<Guide />} />
                                <Route path='/changepassword' element={<ChangePassword />} />
                                <Route path='/integration' element={<Integration />} />
                                <Route path='/billing' element={<Billing />} />
                                <Route path='/transactions' element={<Transactions />} />
                                <Route path='/user' element={<User />} />
                                
                            </>
                    }


                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Routing