import {REDIRECT_URI} from "../config/constants";

export const linkedinRedirectUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${'77po1lyqd7yi79'}&redirect_uri=${REDIRECT_URI}&scope=r_liteprofile,r_emailaddress`
export const handleLinkedinLogin = () => {
    window.location.href = linkedinRedirectUrl
}

export const logoutLinkedin = (url) => {
    console.log("logout", url)
    // Invisible window popup
    const win = window.open(
        url,
        '_blank',
        'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=0, height=0, visible=none'
    );

    // win.style.visibility = "hidden"

    setTimeout(() => {
        win.close();
    }, 5000);
}