import React from 'react'
import sales from '../../img/sales-force.png'
import hubspot from '../../img/hubspot.jpg'
import reply from '../../img/reply.png'
import slack from '../../img/slack.png'
import smart from '../../img/smart.avif'
import sheet from '../../img/sheets.png'
import SettingTab from '../Tabs/SettingTab'
import Header from '../Header/Header'
const Integration = () => {
    return (
        <>
            <Header billing="Billing" account="Account information" home="Home" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5">
                        <div className="row">
                            <div className="col-md-4 mb-3 text-center box-shadow p-2 rounded">
                                <img src={sales} className='img-fluid img-sales' alt="" />
                                <h4>Salesforce</h4>
                            </div>
                            <div className="col-md-4 mb-3 text-center  box-shadow p-2 rounded">
                                <img src={hubspot} className='img-fluid img-sales' alt="" />
                                <h4>Hubspot</h4>
                            </div>
                            <div className="col-md-4 mb-3 text-center  box-shadow p-2 rounded">
                                <img src={reply} className='img-fluid img-sales' alt="" />
                                <h4>Reply.io</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3  text-center  box-shadow p-2 rounded">
                                <img src={slack} className='img-fluid img-sales' alt="" />
                                <h4>Slack</h4>
                            </div>
                            <div className="col-md-4 mb-3   text-center  box-shadow p-2 rounded">
                                <img src={smart} className='img-fluid img-sales' alt="" />
                                <h4>Smartlead</h4>
                            </div>
                            <div className="col-md-4 mb-3  text-center  box-shadow p-2 rounded">
                                <img src={sheet} className='img-fluid img-sales' alt="" />
                                <h4>Google Sheets</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Integration