import { React, useState, useEffect, useLayoutEffect } from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import './Login.css'
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';
import { handleLinkedinLogin } from '../../helpers/LinkedIn';
import LinkedInPage2 from "../../getURL"
import CircularColor from '../../helpers/Loading';
import { DASHBOARD_BACKEND_PATH } from "../../config/constants";
import logo from '../../img/logo.png'
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [tab1, settab1] = useState(true);
    const [tab2, settab2] = useState(false);
    const [showPassword, setShowPassword] = useState(true)
    const [showCPassword, setShowCPassword] = useState(true)

    const [nameregister, setnameregister] = useState("")
    const [numberregister, setnumberregister] = useState("")
    const [emailregister, setemailregister] = useState("")
    const [passwordregister, setpasswordregister] = useState("")
    const [conpasswordregister, setconpasswordregister] = useState("")

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const hasReferral = searchParams.has('reference');

    useEffect(() => {
        if (hasReferral) {
            settab2(true);
            settab1(false);
            const reference = searchParams.get('reference');
            axios.post(DASHBOARD_BACKEND_PATH + `/referral/info`, {
                reference
            }).then(res => {
                const referral = res.data?.referral;
                if (referral) {
                    setnameregister(referral.referee_name);
                    setemailregister(referral.referee_email);
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: err.response.data,
                }).then(() => {
                    navigate('/')
                })
            });
        }
    }, [hasReferral])

    useLayoutEffect(() => {
        if (searchParams.has('code')) {
            setIsLoading(true);
            const code = searchParams.get('code');
            // axios.get('http://localhost:7000/auth/Linked-in-auth', {
            axios.get(`${DASHBOARD_BACKEND_PATH}/auth/Linked-in-auth`, {
                headers: {
                    auth_code: code
                }
            })
                .then(res => {
                    console.log("scope_data", res.data)

                    if (res.data != null || res.data != undefined) {
                        localStorage.setItem('email', res.data.data.email)
                        localStorage.setItem('name', res.data.data.name)
                        localStorage.setItem('loginType', "linkedIn")
                        window.location.reload(false)
                        // navigate("/home");
                    }
                })
                .catch(console.log)

        }

        return () => {

        }

    }, [])

    const login = () => {
        if (email == "") {
            swal("Enter Email", "", "error");
        }
        else if (password == "") {
            swal("Enter Password", "", "error");
        }
        else {
            fetch(`${DASHBOARD_BACKEND_PATH}/auth/Login`, {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    "email": email,
                    "password": password
                })
            }).then(res => {
                return res.json()
            }).then(ress => {
                if (ress.message == 'successfully login') {
                    localStorage.setItem('id', ress.data.id)
                    localStorage.setItem('email', ress.data.email)
                    localStorage.setItem('number', ress.data.number)
                    localStorage.setItem('name', ress.data.name)
                    window.location.reload(false)
                }
                else {
                    swal("Invalid Email", "", "error");
                }
            })
        }
    }


    const Registered = async () => {

        if (nameregister == "") {
            swal("Enter Name", "", "error");
        }
        else if (numberregister == "") {
            swal("Enter Number", "", "error");
        }
        else if (emailregister == "") {
            swal("Enter Email", "", "error");
        }
        else if (passwordregister == "") {
            swal("Enter Password", "", "error");
        }
        else if (conpasswordregister == "") {
            swal("Enter confirm Password", "", "error");
        }
        else if (passwordregister !== conpasswordregister) {
            swal("Password Does Not Match", "", "error");
        }
        else {
            const params = {
                "name": nameregister,
                "number": numberregister,
                "email": emailregister,
                "password": passwordregister,
                "type": "simple"
            };
            if (hasReferral) {
                params["referral"] = searchParams.get('reference')
            }

            fetch(`${DASHBOARD_BACKEND_PATH}/auth/SignUp`, {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(params)
            }).then(res => {
                return res.json()
            }).then(ress => {
                if (ress.message === 'Successfull') {
                    searchParams.delete('reference');
                    setSearchParams(searchParams);
                    window.location.reload(false)
                }
                else {
                    swal("Something Went Wrong Please Try Again With Another Email", "", "error")
                }
            })
        }
    }

    if (isLoading) {
        return <CircularColor />
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 login-bg">
                        <div className="container border rounded-1 box-login-shadow login-container bg-white p-4 pt-3 pb-3">
                            <div className="row pb-3 d-none">
                                <div className="col-md-12 ">
                                    {
                                        hasReferral ?
                                            <h5 className="text-dark cursor fw-normal tab-active fs-14 text-center">Referral Sign Up</h5> :
                                            <div className="tab-heading d-flex align-items-center justify-content-around gap-5">
                                                <h5
                                                    onClick={() => {
                                                        settab1(true)
                                                        settab2(false)
                                                    }}
                                                    className={tab1 === true ? "text-dark cursor fw-normal tab-active fs-14" : " text-dark cursor fw-normal fs-14"}>Login</h5>
                                                <h5
                                                    onClick={() => {
                                                        settab2(true)
                                                        settab1(false)
                                                    }}
                                                    className={tab2 === true ? "text-dark cursor fw-normal tab-active fs-14" : " text-dark cursor fw-normal fs-14"}>Sign Up</h5>
                                            </div>
                                    }

                                </div>
                            </div>
                            <div className={hasReferral ? "custom-border-full d-none" : (tab2 === true ? "custom-border-right d-none" : "custom-border-left d-none")}></div>
                            <div className="row">
                                <div className="col-md-12 text-center ">
                                    <div className={tab1 === true ? "login-tab" : "d-none "} >
                                    <div className="row">
                                            <div className="col-md-12 set-the-div-4">
                                                <img src={logo} alt="" />
                                                </div>
                                                </div>

                                    <div className="row">
                                            <div className="col-md-12 mb-3">
                                                </div>
                                                <h4 className='fw-bold m-0 p-0'>Sign in to your account</h4>
                                                <div className='set-the-div-2'>
                                                    <p>Or</p>
                                                <button
                                                    onClick={() => {
                                                        settab2(true)
                                                        settab1(false)
                                                    }}
                                                    className="set-the-btn-1">
                                                    start a free trial
                                                </button>
                                                </div>
                                                </div>
                                        
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3 p-0">
                                                <span className='text-dark fs-14'>Email</span>
                                                <input
                                                    placeholder='Email'
                                                    onChange={(e) => setemail(e.target.value)}
                                                    type="email" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3 p-0">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end ">
                                                        {
                                                            showPassword === true ?
                                                                <span
                                                                    onClick={() => setShowPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            login()
                                                        }
                                                    }}
                                                    placeholder='Password'
                                                    onChange={(e) => setpassword(e.target.value)}
                                                    type={showPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                        
                                        
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <div className='text-end'>
                                                <p className='text-dark fs-14'><Link className='text-dark text-decoration-none set-p-1' to='/email'>Forgot your password?</Link></p>
                                                </div>
                                                <button
                                                    onClick={login}
                                                    className="login-btn p-1 w-100 rounded-1 text-white">
                                                    Sign in
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center mt-3 mb-3">
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                            <div className="col-md-4">
                                                <span className="text-dark border-color-grey">OR</span>
                                            </div>
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                
                                                <button onClick={(e) => {
                                                    handleLinkedinLogin()
                                                }} className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                    {/* <LinkedInPage2 /> */}
                                                    <i className="fa fa-linkedin me-2 mt-n1 text-primary" aria-hidden="true" />
                                                    LinkedIn Login
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <div className='set-the-div-3'>
                                                <p className='text-dark fs-12 text-start font-italic'>By login in, you agree to Trigify <span>Terms of Service</span> and <span>Privacy Policy</span>.</p>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="row d-none">
                                            <div className="col-md-12 text-center my-3">
                                                <div className="form-check text-center me-2 mt-2">
                                                    <input className="" type="checkbox" value="" defaultChecked />
                                                    <span className='dark-grey fs-14'>  Keep me signed in</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className={tab2 === true ? "signup-tab" : "d-none"}>

                                    <div className="row">
                                            <div className="col-md-12 set-the-div-4">
                                                <img src={logo} alt="" />
                                                </div>
                                                </div>

                                    <div className="row">
                                            <div className="col-md-12 mb-3">
                                                </div>
                                                <h4 className='fw-bold m-0 p-0'>Sign up to your account</h4>
                                                <div className='set-the-div-2'>
                                                    <p>Or</p>
                                                <button
                                                    onClick={() => {
                                                        settab2(false)
                                                        settab1(true)
                                                    }}
                                                    className="set-the-btn-1">
                                                    sign in
                                                </button>
                                                </div>
                                                </div>

                                        
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Name</span>
                                                <input
                                                    onChange={(e) => setnameregister(e.target.value)}
                                                    value={nameregister}
                                                    placeholder='Name'
                                                    type="text" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Mobile Number</span>
                                                <input
                                                    onChange={(e) => setnumberregister(e.target.value)}
                                                    placeholder='Mobile Number'
                                                    type="number" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <span className='text-dark fs-14'>Email</span>
                                                <input
                                                    onChange={(e) => setemailregister(e.target.value)}
                                                    placeholder='Email'
                                                    value={emailregister}
                                                    type="email" className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        {
                                                            showPassword === true ?
                                                                <span
                                                                    onClick={() => setShowPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input onChange={(e) => setpasswordregister(e.target.value)} placeholder='Password...' type={showPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-start mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-dark fs-14'>Confirm Password</span>
                                                    </div>
                                                    <div className="col-md-6 text-end">
                                                        {
                                                            showCPassword === true ?
                                                                <span
                                                                    onClick={() => setShowCPassword(false)}
                                                                    className="eye-closed cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                                        <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                                        <path d="m1 1 22 22" />
                                                                        <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span
                                                                    onClick={() => setShowCPassword(true)}
                                                                    className="eye cursor">
                                                                    <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                                        <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <input onChange={(e) => setconpasswordregister(e.target.value)} placeholder=' Confirm Password...' type={showCPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent form-input-paddding" />
                                            </div>
                                        </div>

                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button onClick={() => Registered()} className="login-btn p-1 mt-2 w-100 rounded-1 text-white">
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center align-items-center mt-3 mb-3">
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                            <div className="col-md-4">
                                                <span className="text-dark border-color-grey">OR</span>
                                            </div>
                                            <div className="col-md-4 border-bottom border-color-grey"></div>
                                        </div>

                                        {
                                            !hasReferral &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button className="google-btn border rounded-1 w-100 p-1 fs-14">
                                                        <LinkedInPage2 />
                                                    </button>
                                                </div>
                                            </div>
                                        }


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-5 bg-primary right-bg">
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Login