import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header/Header'
import SettingTab from '../Tabs/SettingTab';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './Billing.css'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from '../StripeCheckout';
import axios from 'axios';
import {DASHBOARD_BACKEND_PATH} from "../../config/constants";
import Swal from 'sweetalert2'
import swal from "sweetalert";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
};

const Billing = () => {
    const [packages, setPackages] = useState([])
    const [selectedPackage, setSelectedPackage] = useState(0)
    const [saveCardValue, setSaveCardValue] = useState('')
    const [saveCardCvc, setSaveCardCvc] = useState('')
    const [saveCardMonth, setSaveCardMonth] = useState('')
    const [saveCardZip, setSaveCardZip] = useState('')
    const [saveCardYear, setSaveCardYear] = useState('')
    const [savedCardArray, setSavedCardArray] = useState([])
    const [open, setOpen] = React.useState(false);
    const [openCardModal, setOpenCardModal] = React.useState(false);
    const [updateScreen, setUpdateScreen] = useState(false)

    const handleOpenCardModal = () => setOpenCardModal(true);
    const handleCloseCardModal = () => setOpenCardModal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const freePackageSelectionHandler = () => {
        Swal.fire({
            title: 'Unsubscribe from Premium Plan',
            text: 'Are you sure you want to cancel your Premium Plan subscription and move back to the Free Plan?',
            confirmButtonText: 'Yes, Cancel Plan',
            cancelButtonColor: '#5e72e4',
            confirmButtonColor: '#f20086',
            showCancelButton: true,
            cancelButtonText: "No, Keep Premium Plan",
            confirmButtonAriaLabel: 'danger'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const {data} = await axios.post(
                    `${DASHBOARD_BACKEND_PATH}/api/subscription/move-to-free`, {uid: localStorage.getItem("id")}
                );
                swal(data.success ? 'Success!':'Oops!', data.message, data.success ? 'success': 'error').then(() => {
                    if (data.success) {
                        window.location.reload();
                    }
                });
            }
        })
    }


    useEffect(() => {
        if (packages.length < 1) {
            fetch(`${DASHBOARD_BACKEND_PATH}/getPackages?uid=${localStorage.getItem("id")}`, {
                method: "GET",
                headers: { 'content-type': 'application/json' }
            }).then(res => res.json())
                .then(res => {
                setPackages(res.data || []);
            })
        }

        axios.get(DASHBOARD_BACKEND_PATH+`/get-card-details?uid=${localStorage.getItem("id")}`).then(res => {
            setSavedCardArray(res.data.data)
        })

    }, [updateScreen])

    const stripePromise = loadStripe('pk_test_51JZDmyGpONMliSte49UV1EdU2vzpJZOvKZsGUujbK0J2dl86NTqlhbbSMZ7qzpnkJBW5ebFA6eRfkrHl8OtcJ9OA0000iLiprP');

    const handleSaveDetails = () => {
        let obj = {
            number: saveCardValue,
            cvc: saveCardCvc,
            month: saveCardMonth,
            year: saveCardYear,
            zip: saveCardZip,
            user_id: localStorage.getItem("id")
        }
        axios.post(DASHBOARD_BACKEND_PATH + '/save-user-card-details', obj).then(res => {
            alert(res.data.message);
            setUpdateScreen(state => !state);
            handleCloseCardModal();
            setSaveCardYear('');
            setSaveCardZip('');
            setSaveCardMonth('');
            setSaveCardCvc('');
            setSaveCardValue('');

        })
    }

    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home" />
            <div className="container-fluid">
                <Modal
                    open={openCardModal}
                    onClose={handleCloseCardModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h1>Save Card Details</h1>
                        <input className='input_styles' type='text' value={saveCardValue} onChange={(e) => setSaveCardValue(e.target.value)} placeholder='Enter Card Number' />
                        <input className='input_styles' type='text' value={saveCardCvc} onChange={(e) => setSaveCardCvc(e.target.value)} placeholder='Enter Cvc' />
                        <input className='input_styles' type='text' value={saveCardMonth} onChange={(e) => setSaveCardMonth(e.target.value)} placeholder='Enter Months' />
                        <input className='input_styles' type='text' value={saveCardYear} onChange={(e) => setSaveCardYear(e.target.value)} placeholder='Enter year' />
                        <input className='input_styles' type='text' value={saveCardZip} onChange={(e) => setSaveCardZip(e.target.value)} placeholder='Enter Zip Code' />
                        <button className="input_btn_styles" onClick={handleSaveDetails}>Save Details</button>
                    </Box>
                </Modal>
                <div className="row">
                    <div className="col-md-3 p-0">
                        <SettingTab />
                    </div>
                    <div className="col-md-9 mt-5 text-dark bg-white p-4 rounded-1">
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Manage Plan</h1>
                            </div>
                            <div className="col-md-6 text-end">
                                <Link to="/" className="btn btn-primary w-50 rounded-1 set-btn-1">Cancel</Link>
                            </div>
                        </div>
                        <div className="row">
                            {
                                packages?.length ? packages.map(pkg => (
                                    <div className="col-md-4 mt-3 text-center ">
                                        <div className="box rounded bg-white mb-2 set">
                                            <h4 className='text-center'>{pkg.name}</h4>
                                            <ul>
                                                {pkg.price && <li>${pkg.price} per month</li>}
                                                {pkg.initial_leads_limit && <li>First month leads {pkg.initial_leads_limit}</li>}
                                                {pkg.leads_limit && <li>Leads per month {pkg.initial_leads_limit} - {pkg.leads_limit}</li>}
                                                {<li>{pkg.triggers_limit === 0 ? "Unlimited" : pkg.triggers_limit} Triggers</li>}
                                                {pkg.integration && <li>{pkg.integration}</li>}
                                                {pkg.support && <li>{pkg.support}</li>}
                                            </ul>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <button disabled={pkg.is_current} className="btn btn-primary rounded-1 set-btn-1" onClick={() => {
                                                    pkg.id === 1 ? freePackageSelectionHandler() : handleOpen();
                                                    setSelectedPackage(pkg)
                                                }}>{!pkg.is_current ? 'Select Plan' : 'Current Plan'}</button>
                                            </div>
                                        </div>
                                    </div>
                                )) : "data not found"
                            }
                        </div>
                    </div>
                </div>
                <Elements stripe={stripePromise}>
                    <StripeCheckout open={open} handleOpen={handleOpen} handleClose={handleClose} selectedPackage={selectedPackage} data={savedCardArray} />
                </Elements>
            </div >
        </>
    )
}

export default Billing