import React, {useEffect, useState, useContext} from 'react'
import './HomePage.css';
import Header from '../Header/Header'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Space} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import {DASHBOARD_BACKEND_PATH, SCRAPPER_BACKEND_PATH} from "../../config/constants";
import {getFormattedDate} from "../../helpers/date.helper";
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import JobTrigger from '../../pages/JobTrigger';
import ProfileTrigger from '../../pages/ProfileTrigger';
import CompanyTrigger from '../../pages/CompanyTrigger';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const filters = [
    {
        key: 'current_week',
        label: 'this week',
    },
    {
        key: 'current_month',
        label: 'this month',
    },
    {
        key: 'current_year',
        label: 'this year',
    },
];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #f20086',
    boxShadow: 24,
    p: 2,
};
const HomePage = () => {
    const {register, handleSubmit, reset} = useForm();
    const [expanded, setExpanded] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [stats, setStats] = useState({});
    const [filter, setFilter] = useState(filters[0]);
    const [triggersData, setTriggersData] = useState({
        company: [],
        job: [],
        profile: []
    });

    const filterChangeHandler = (e) => {
        const selectedFilter = filters.find(f => f.key === e.key);
        setFilter(selectedFilter);
    }
    useEffect(() => {
        axios.get(DASHBOARD_BACKEND_PATH + `/get-stats?uid=${localStorage.getItem("id")}&filter=${filter.key}`).then(res => {
            setStats(res.data);
        });
    }, [filter]);

    useEffect(() => {
        axios.get(`${SCRAPPER_BACKEND_PATH}/triggers/${localStorage.getItem("id")}`)
            .then(res => {
                if (res.data?.data) {
                    setTriggersData(res.data.data)
                }
            });
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseX = () => setOpen(false);

    const invitationFormHandler = data => {
        axios.post(DASHBOARD_BACKEND_PATH + `/referral/invite`, {
            uid: localStorage.getItem("id"),
            ...data
        }).then(res => {
            const data = res.data;
            if (data.success) {
                toast.success(data.message);
                reset();
                handleClose();
            }
        }).catch(err => {
            toast.error(err.response.data);
        });
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const hasAnySavedTrigger = (triggersData.company.length > 0 || triggersData.job.length > 0 || triggersData.profile.length > 0);

    return (
        <>
            <Header Settrigger="Trigger" Mytrigger="Home"/>

            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 ">
                        <div className="home-left-box bg-white rounded mt-3 text-dark set-p-div_1">
                            <h3>Welcome</h3>
                            <h3 className='purple-heading'>{localStorage.getItem('name')}</h3>
                            <hr/>
                            <p>Your plan</p>
                            <p><b>{stats.package?.name ?? ''}</b></p>
                            <p>Renewal: {getFormattedDate(stats.package?.expiry_date)}</p>
                            <hr/>
                            <div className="home-left-detail">
                                {/* <p>Team members</p> */}
                                {/* <p><b>1/1</b></p> */}
                                <button className='set_btn_2' onClick={handleOpen}><p className='text-purple'>Invite
                                    members</p></button>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="home-mid-box  mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className='text-dark m-0'>Set up your first search <span>...</span></h4>
                                </div>
                                <div className="col-md-12">
                                    <h5 className='text-dark text-center mt-3 mb-3'>Pick your Trigger</h5>
                                </div>
                            </div>
                            <div className="row mb-3">

                                <div className="col-md-6">
                                    <div className=" text-center new-in-roles-box  rounded h-100 set-div-2">
                                        <Link className="p-4 nav-link  position-relative" to='/userprofile?type=profileTriggerType'>
                                            <h5>New in Role</h5>

                                            <p className='p-0 m-0'>
                                                Find prospects that have recently
                                                started a new role & filter by the
                                                legnth of time.
                                            </p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className=" text-center new-in-roles-box  rounded h-100 set-div-2">
                                        <Link className="p-4 nav-link  position-relative" to='/account?type=companyTriggerType'>
                                            <h5>Funding Round</h5>

                                            <p className='p-0 m-0'>
                                                Find companies that have
                                                recently recevied funding.
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">

                                <div className="col-md-6 ">
                                    <div className=" text-center hiring-box  rounded h-100 set-div-2">
                                        <Link className="p-4 nav-link  position-relative" to='/userprofile?type=jobTriggerType'>
                                            <h5>Key Word Search</h5>

                                            <p className='p-0 m-0'>
                                                Search job description to find
                                                companies talking about specific
                                                strategies, technology and more.
                                            </p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className=" text-center hiring-box  rounded h-100 set-div-2">
                                        <Link className="p-4 nav-link  position-relative" to='/account?type=jobTriggerType'>
                                            <h5>Hiring Alerts</h5>

                                            <p className='p-0 m-0'>
                                                Find companies hiring specific
                                                roles.
                                            </p>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            hasAnySavedTrigger
                            && <div className="home-mid-box  mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className='text-dark'>Jump back in <span>...</span></h3>
                                    </div>
                                </div>

                                {
                                    triggersData.profile.length > 0
                                    && <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div>
                                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                    <AccordionSummary className="set-collapse-1" aria-controls="panel1d-content"
                                                                      id="panel1d-header">
                                                        <Typography>New in Role</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography component={'span'} variant={'body2'}
                                                                    className='set-collapse-2'>
                                                            <ProfileTrigger triggers={triggersData.profile}/>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    triggersData.job.length > 0
                                    && <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div>
                                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                    <AccordionSummary className="set-collapse-1" aria-controls="panel2d-content"
                                                                      id="panel2d-header">
                                                        <Typography>Hiring</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography component={'span'} variant={'body2'}>
                                                            <JobTrigger triggers={triggersData.job}/>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    triggersData.company.length > 0
                                    && <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div>
                                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                    <AccordionSummary className="set-collapse-1" aria-controls="panel3d-content"
                                                                      id="panel3d-header">
                                                        <Typography>Funding</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography component={'span'} variant={'body2'}>
                                                            <CompanyTrigger triggers={triggersData.company} component={'span'}
                                                                            variant={'body2'}/>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="col-md-3 mt-3 ">
                        <div className="home-right-box  bg-white rounded p-2 text-dark">
                            <h3>Usage</h3>
                            <h3>Analytics</h3>
                            <div className="home-right-dropdown mb-3">
                                <Dropdown
                                    menu={{
                                        items: filters,
                                        onClick: filterChangeHandler
                                    }}
                                    trigger={['click']}
                                    on
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            {filter.label}
                                            <DownOutlined/>
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>

                            <p>
                                <span className="glass me-2">
                                    <svg width={22} height={22} fill="#000" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8Zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6Z"/>
                                    </svg>
                                </span>

                                {stats.totalSearches} searches ran

                            </p>
                            <p>
                                <span className="user me-2">
                                    <svg width={22} height={22} fill="#000" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1h2Z"/>
                                    </svg>
                                </span>

                                {stats.totalNewLeads} new leads

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='set_div_2'
                >
                    <form onSubmit={handleSubmit(invitationFormHandler)}>
                        <Box sx={style}>
                            <div className='set_div_1'>
                                <button onClick={handleCloseX}>X</button>
                            </div>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Enter Email To Invite Members
                            </Typography>
                            <Typography id="modal-modal-description">
                                <TextField required {...register('name')} className='set_input_1' label="Name"
                                           variant="outlined"/>
                                <TextField required type='email' {...register('email')} className='set_input_1'
                                           label="Email"
                                           variant="outlined"/>
                            </Typography>
                            <Typography className="text-end">
                                <Button variant="outlined" color="primary"
                                        type="submit" className='mt-3'>Invite</Button>
                            </Typography>

                        </Box>
                    </form>
                </Modal>
            </div>
        </>
    )
}

export default HomePage